<script setup lang="ts">
import { makeFormModel, setState, getChildModel, toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { financialAmountField } from 'ah-common-lib/src/form/models';
import { optional, smallerThanOrEqualsParam } from 'ah-common-lib/src/form/validators';
import { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { computed, PropType, reactive, watch } from 'vue';
import { useAhTradesState } from '../../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { VCol, VRow } from 'ah-common-lib/src/common/components';
import FormattedCurrency from '../FormattedCurrency.vue';

const props = defineProps({
  currency: { type: String, required: true },
  disablePostCollateral: {
    type: Boolean,
    default: false,
  },
  model: { type: Object as PropType<{ amount: number }>, default: () => ({}) },
  ownerId: {
    type: String,
    required: true,
  },
  type: {
    type: String as PropType<'post' | 'withdraw'>,
    required: true,
  },
});

const transactionFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'transactionForm',
    title: '',
    fieldType: 'form',
    fields: [
      financialAmountField(
        'amount',
        '',
        {
          availableBalance: 0,
          errorMessages: {
            operationMaximum: "You don't have enough funds to perform this operation.",
          },
        },
        {
          operationMaximum: optional(smallerThanOrEqualsParam('amount', 'availableBalance')),
        }
      ),
    ],
  }),
  validation: null,
});

const emit = defineEmits<{
  (e: 'update:model', value: { amount?: number }): void;
  (e: 'update:validation', value: FormValidation | null): void;
}>();

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useAhTradesState();

const isPartnerUser = computed(() => !onBehalfOfClient.value && !tradeState.store.useAuthStore().isClientUser);

const wallet = computed(() => {
  return tradeState.store.useWalletsStore().getWallet(props.currency, {
    isPartner: isPartnerUser.value,
    id: props.ownerId,
  });
});

const availableBalance = computed(() => {
  if (!wallet.value) {
    return 0;
  }
  return (props.type === 'post' ? wallet.value.availableBalance : wallet.value.collateralBalance) ?? 0;
});

watch(
  () => [wallet.value, props.type],
  () => {
    const amountField = getChildModel(transactionFormDef.form, 'amount')!;

    if (wallet.value) {
      transactionFormDef.form.amount = Math.min(transactionFormDef.form.amount, wallet.value.availableBalance);
    }

    setState(amountField, 'readonly', availableBalance.value === 0 || props.disablePostCollateral);
    setState(amountField, 'availableBalance', availableBalance.value);
    setState(amountField, 'errorMessages', {
      operationMaximum:
        props.type === 'post'
          ? `${!onBehalfOfClient.value ? "You don't" : "Client doesn't"} have enough funds to perform this operation.`
          : 'Exceeded the amount of collateral available in this currency.',
    });
  },
  { immediate: true }
);

watch(
  () => props.model,
  () => {
    if (props.model) {
      updateModel(transactionFormDef.form, props.model);
    }
  },
  { immediate: true }
);

function onFormEvent() {
  emit('update:model', toDataModel(transactionFormDef.form));
}

watch(
  () => transactionFormDef.validation,
  () => emit('update:validation', transactionFormDef.validation),
  {
    immediate: true,
  }
);
</script>

<template>
  <VRow alignV="start" alignH="end">
    <VCol class="bold pt-2" cols="1">
      {{ currency }}
    </VCol>
    <VCol cols="5" class="themed text-right">
      <FormattedCurrency :value="availableBalance" />
    </VCol>
    <VCol cols="6">
      <ValidatedForm
        :fm="transactionFormDef.form"
        :validation.sync="transactionFormDef.validation"
        @form-event="onFormEvent"
      />
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.themed,
.bold {
  font-weight: $font-weight-bold;
}

.themed {
  @include themedTextColor($color-primary, $color-dark-primary);
}
</style>
