var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.VRow,{staticClass:"spot-limits"},[_c(_setup.VCol,{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Spot Limits")])]),_c(_setup.VCol,{staticClass:"mb-2",attrs:{"cols":"12"}},[_c(_setup.ValidatedForm,{attrs:{"fm":_setup.spotLimitsFormDef.form,"validation":_setup.spotLimitsFormDef.validation},on:{"update:validation":function($event){return _vm.$set(_setup.spotLimitsFormDef, "validation", $event)}},scopedSlots:_vm._u([{key:"spotLimitsForm.amount:prepend",fn:function(){return [_c('BInputGroupText',[_vm._v(" "+_vm._s(_vm.currency)+" ")])]},proxy:true}])})],1),_c(_setup.VCol,{staticClass:"mb-2",attrs:{"cols":"12"}},[_c(_setup.SliderInput,{attrs:{"value":_setup.selectedAmount,"min":{
        value: _setup.DEFAULT_ALLOWED_LIMIT_VALUES.min,
        label: `min ${_vm.currency} ${_setup.formatCurrencyValue(_setup.DEFAULT_ALLOWED_LIMIT_VALUES.min)}`,
      },"max":{
        value: _setup.dynamicMaxLimit,
        label: `${_vm.currency} ${_setup.formatCurrencyValue(_setup.dynamicMaxLimit)}`,
      },"showThumb":false,"showMarkers":"","showColorRanges":"","markers":10},on:{"update:value":function($event){_setup.selectedAmount=$event}},scopedSlots:_vm._u([{key:"colorRanges",fn:function(){return [_c('div',{class:[
            'slider-utilised-limit',
            {
              'end-border-radius': _setup.utilizedLimitSliderPercentage === 100,
              'show-on-top': _vm.currentLimit > _vm.utilisedLimit && _setup.selectedAmount >= _vm.utilisedLimit,
            },
          ],style:({ width: `${_setup.utilizedLimitSliderPercentage}%` })}),_c('div',{class:[
            'slider-current-limit',
            {
              'start-border-radius': _setup.utilizedLimitSliderPercentage === 0 || _vm.utilisedLimit > _vm.currentLimit,
              'end-border-radius': _setup.utilizedLimitSliderPercentage + _setup.currentLimitSliderPercentage === 100,
              'show-on-top': _vm.utilisedLimit > _vm.currentLimit && _setup.selectedAmount >= _vm.currentLimit,
            },
          ],style:({
            width: `${_setup.currentLimitSliderPercentage}%`,
            left: `${_vm.utilisedLimit > _vm.currentLimit ? 0 : _setup.utilizedLimitSliderPercentage}%`,
          })})]},proxy:true},{key:"currentValueColorRange",fn:function({ currentValuePercentage }){return [_c('div',{class:[
            'slider-current-value',
            {
              'show-on-top':
                currentValuePercentage <= _setup.utilizedLimitSliderPercentage ||
                currentValuePercentage <
                  (_vm.utilisedLimit > _vm.currentLimit
                    ? _setup.utilizedLimitSliderPercentage
                    : _setup.currentLimitSliderPercentage + _setup.utilizedLimitSliderPercentage),
            },
          ],style:({ width: `${currentValuePercentage}%` })})]}}])})],1),_c(_setup.VCol,{staticClass:"chart-legend",attrs:{"cols":"12"}},[_c(_setup.ChartLegend,{attrs:{"chartLegendData":_setup.CHART_LEGEND_DATA}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }