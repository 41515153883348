<script setup lang="ts">
import { computed, ref } from 'vue';
import { generateUID } from 'ah-common-lib/src/helpers/uuid';
import { useTheme } from 'ah-theme';

const uid = ref(generateUID(6));
const theme = useTheme();

const helpLink = computed(() => theme.val?.helpURL);
const helpEmail = computed(() => theme.val?.helpEmail);

const goToHelpLink = () => {
  window.open(helpLink.value, '_blank');
};
</script>

<template>
  <div class="app-help-button-holder">
    <VButton
      blurOnClick
      @click="goToHelpLink"
      v-if="helpLink"
      class="btn-stroked need-help"
      :id="`${uid}-app-help-button`"
    >
      NEED HELP?
    </VButton>
    <BTooltip :target="`${uid}-app-help-button`" triggers="hover" boundary="window" custom-class="info-tooltip">
      <p class="mb-0 tooltip-text">
        Click on the button to see our FAQs page.<br />
        <template v-if="helpEmail">
          If you don’t find what you’re looking for please
          <a target="_blank" :href="`mailto:${helpEmail}`">send us an e-mail</a> and we’ll get back to you as soon as
          possible.
        </template>
      </p>
    </BTooltip>
  </div>
</template>

<style lang="scss" scoped>
.app-help-button-holder {
  display: flex;
  align-items: center;
}

.need-help {
  display: inline-block;
  padding: 0.37em 0.75em;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  border-radius: 0.37em;
  margin-left: 2em;
  margin-right: 0.7em;
  @include themedTextColor($color-primary, $color-primary);
}

.tooltip-text {
  max-width: 20rem;
}
</style>
