<script lang="ts" setup>
import {
  PaginatedQuery,
  Trade,
  ExportListType,
  getPrimaryCcy,
  AmountType,
  getCurrencyPair,
  formatHedgingInstrument,
  HedgingInstruments,
} from 'ah-api-gateways';
import { defineUseManagedListingProps, useManagedListing, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { PropType, computed, reactive } from 'vue';
import { tradeReportTableFieldsFull } from '../../models/tradeTableFields';
import TradeNotionalValueCell from '../tableCells/TradeNotionalValueCell.vue';
import TradeStatusPill from '../tableCells/TradeStatusPill.vue';
import TradeFxRateCell from '../tableCells/TradeFxRateCell.vue';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';
import { useAhTradesState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { capitalize } from 'lodash';
import { formatCurrencyValue } from '../../../../ah-common-lib/src/helpers/currency';
import { formatInTimeZone } from 'date-fns-tz';

const props = defineProps({
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    required: false,
    default: () => ({ tableFields: tradeReportTableFieldsFull }),
  },
  hidePagination: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
  documentTitle: {
    type: String,
    required: false,
  },
  itemsLabel: {
    type: String,
    required: false,
    default: 'trades',
  },
  tradeStatusLabel: {
    type: String,
    required: false,
    default: 'status',
  },
  ...defineUseManagedListingProps<Trade>(),
});

const state = reactive({
  percentageToBasisConversion: 100,
});

const tradeState = useAhTradesState();

interface TradeEmits extends UseManagedListingEmits<Trade> {}

const emit = defineEmits<TradeEmits>();

const managedListing = useManagedListing<Trade>({
  loadDataRequest(query: PaginatedQuery) {
    return tradeState.services.trade.listTrades(query);
  },
  downloadDataRequest(type: string, query: { [key: string]: any }) {
    return tradeState.services.trade.downloadTradeList(query, type as ExportListType, props.documentTitle);
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: useRequestManager().manager,
});

function getTradeAmountType(trade: Trade) {
  return getPrimaryCcy(trade).amountType === AmountType.SELL ? 'Sell' : 'Buy';
}

function getTradeCcyPair(trade: Trade) {
  return getCurrencyPair(trade);
}

function isVanilla(trade: Trade) {
  return trade.hedgingProduct === HedgingInstruments.VANILLA_OPTIONS;
}

const isDrawdown = computed(() => {
  return (trade: Trade) => {
    return !!trade.parentTradeId;
  };
});

function getSettlementDate(trade: Trade, dateFormat: string) {
  if (trade.settlementDate) {
    return formatInTimeZone(new Date(trade.settlementDate), 'GMT', dateFormat);
  }

  return '-';
}

defineExpose({ loadData: managedListing.loadData, downloadData: managedListing.listeners.download });
</script>

<template>
  <PaginatedTable
    fixed
    hover
    animateCols
    :class="['trades-table', { 'hide-pagination': hidePagination !== false }]"
    :itemsLabel="itemsLabel"
    :tradeStatusLabel="tradeStatusLabel"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs }"
    :stacked="tradeState.mediaQuery.is('smDown')"
  >
    <template #cell(createdAt)="{ item }">
      {{ formatDate(item.createdAt, SIMPLE_DATE_FORMAT) }}
    </template>
    <template #cell(expiryDate)="{ item }">
      {{ formatDate(item.expiryDate, SIMPLE_DATE_FORMAT) }}
    </template>
    <template #cell(settlementDate)="{ item }">
      <VRow>
        <VCol cols="12">{{ getSettlementDate(item, SIMPLE_DATE_FORMAT) }}</VCol>
        <VCol cols="12" v-if="!isVanilla(item)">{{ getSettlementDate(item, 'HH:mm') }} GMT</VCol>
      </VRow>
    </template>
    <template #cell(windowStartDate)="{ item }">
      {{ formatDate(item.windowStartDate, SIMPLE_DATE_FORMAT) }}
    </template>
    <template #cell(notionalValues)="{ item }">
      <TradeNotionalValueCell :item="item" />
    </template>
    <template #cell(status)="{ item }">
      <TradeStatusPill :status="item.status" v-if="item.status" />
    </template>
    <template #cell(amountType)="{ item }">
      {{ getTradeAmountType(item) }}
    </template>
    <template #cell(ccyPair)="{ item }">
      {{ getTradeCcyPair(item) }}
    </template>
    <template #cell(fxRate)="{ item }">
      <TradeFxRateCell :item="item" />
    </template>
    <template #cell(fxRateWithCCY)="{ item }">
      <TradeFxRateCell show-currency-pair :item="item" />
    </template>
    <template #cell(partnerMarkupPercentage)="{ item }">
      {{ (item.partnerMarkupBasisPoints / state.percentageToBasisConversion).toFixed(2) }}
    </template>
    <template #cell(notionalAmount)="{ item }">
      {{ formatCurrencyValue(item.notionalAmount) }}
    </template>
    <template #cell(claimType)="{ item }">
      {{ capitalize(item.claimType) }}
    </template>
    <template #cell(buyAmount)="{ item }">
      <span class="small-currency">{{ item.ccy2.currency }}</span>
      {{ formatCurrencyValue(item.hasDrawdowns ? item.ccy2.remainingAmount : item.ccy2.amount) }}
      <template v-if="item.hasDrawdowns">
        <br />
        <span class="text-secondary">
          Original:
          <span class="small-currency">{{ item.ccy2.currency }}</span>
          {{ formatCurrencyValue(item.ccy2.amount) }}
        </span>
      </template>
    </template>
    <template #cell(hedgingProduct)="{ item }">
      <div class="position-relative">
        <div>{{ formatHedgingInstrument(item.hedgingProduct) }}</div>
        <div class="drawdown-text mt-n1" v-if="isDrawdown(item)">DRAWDOWN</div>
      </div>
    </template>
    <template #cell(sellAmount)="{ item }">
      <span class="small-currency">{{ item.ccy1.currency }}</span>
      {{ formatCurrencyValue(item.hasDrawdowns ? item.ccy1.remainingAmount : item.ccy1.amount) }}
      <template v-if="item.hasDrawdowns">
        <br />
        <span class="text-secondary">
          Original:
          <span class="small-currency">{{ item.ccy1.currency }}</span>
          {{ formatCurrencyValue(item.ccy1.amount) }}
        </span>
      </template>
    </template>
    <template #cell(rate)="{ item }">
      {{ item.ccy1.clientRate }}
    </template>

    <!-- row details -->
    <template #row-details="row">
      <slot name="row-details" v-bind="row" />
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
.details-icon {
  float: right;
  cursor: pointer;
}

.small-currency {
  font-size: 0.8em;
}

.trades-table.hide-pagination ::v-deep {
  .table-actions {
    display: none;
  }
}

.drawdown-text {
  font-size: 11px;
  @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
  @include desktop {
    position: absolute;
    top: 1.6rem;
  }
}
</style>
