<script setup lang="ts">
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import {
  AuthorityType,
  CompanyRegistrationModel,
  ClientType,
  Permission,
  CompanyQuestionnaire,
  IndividualQuestionnaire,
  Occupation,
} from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { tradingDetailsFM, tradingDetailsFormToModel } from '@/app/helpers/registration/forms';
import { getChildModel, setState, updateModel } from 'ah-common-lib/src/form/helpers';
import { useSettingsStore } from '@/app/store/settingsModule';
import {
  sourceOfFundOptions,
  occupationOptions,
  SourceOfFunds,
  sourceOfFundsLabels,
  occupationLabels,
} from 'ah-common-lib/src/helpers/questionnaire';
import { useAuthStore } from '@/app/store/authStore';
import OBOSettingsForm from './OBOSettingsForm.vue';
import { getServices } from '@/app/services';
import { forkJoin } from 'rxjs';
import IconAlertCircle from 'ah-common-lib/src/icons/components/IconAlertCircle.vue';

const requestManager = useRequestManager().manager;

const services = getServices();

const props = defineProps<{ model: Partial<CompanyRegistrationModel> }>();

const emit = defineEmits<{
  /**
   * 'proceed' - Emitted when valid form is submitted
   */
  (e: 'proceed', value: void): void;
  /**
   * 'update:model' - Emitted when any changes on `model` are done
   */
  (e: 'update:model', value: Partial<CompanyRegistrationModel>): void;
}>();

const settingsStore = useSettingsStore();

const authStore = useAuthStore();

const permissions = ref<Permission[]>([
  { authority: AuthorityType.PAY_ON_BEHALF_OF, allow: true },
  { authority: AuthorityType.TRADE_ON_BEHALF_OF, allow: true },
]);

const oboValidation = ref<FormValidation<Permission[]>>();

const allowedToSetPermissions = computed(
  () => props.model.clientType === ClientType.INDIVIDUAL || props.model.individuals!.find((i) => i.applicant)?.owner
);

const isCompany = computed(() => authStore.isCompanyClient);

const calculatedModel = computed(() => ({
  ...props.model,
  permissions,
  questionnaire: tradingDetailsFormToModel(tradingProfileFM.form, isCompany.value),
}));

const validation = computed(() => {
  return {
    $model: calculatedModel,
    $invalid: !!tradingProfileFM.validation?.$invalid || !!oboValidation.value?.$invalid,
    $dirty: !!tradingProfileFM.validation?.$dirty || !!oboValidation.value?.$dirty,
  };
});

const clientId = computed(() => authStore.loggedInIdentity!.client!.id);

const tradingProfileFM = reactive<FormDefinition>({ form: tradingDetailsFM(isCompany.value), validation: null });

const emirClassificationTooltip = reactive<{
  title: string;
  html: Boolean;
  container: string;
  customClass: string;
  trigger: string;
}>({
  title:
    '<p><b>Financial Counterparties (FCs):</b> These include banks, investment firms, credit institutions, insurance companies, pension funds, fund management companies.</p><p><b>Non-financial counterparties (NFCs):</b> These include all who do not fall into the definition of a FC.</p>',
  html: true,
  container: 'emirClassificationForm',
  customClass: 'emir-classification-tooltip',
  trigger: 'hover',
});

onBeforeMount(() => {
  settingsStore.loadCountries().then(() => {
    const model = getChildModel(tradingProfileFM.form, 'beneficiaryCountries');
    if (model) {
      setState(
        model,
        'options',
        settingsStore.allCountries.map((country) => ({ label: country.name, value: country.cc }))
      );
    }
  });

  settingsStore.loadTradeableCurrencies().then(() => {
    ['sellCurrencies', 'buyCurrencies', 'reportingCurrency'].forEach((field) => {
      const model = getChildModel(tradingProfileFM.form, field);
      if (model) {
        setState(model, 'options', settingsStore.currenciesAsOptions(false));
      }
    });
  });
});

function savePermissions() {
  if (!allowedToSetPermissions.value) {
    return Promise.resolve(null);
  }

  return requestManager
    .cancelAndNew(
      'savePermissions',
      forkJoin(permissions.value.map((p) => services.authz.setClientsPermissions(clientId.value, p)))
    )
    .toPromise();
}

function saveComplianceCase() {
  if (calculatedModel.value.questionnaire.maxExpectedVolume === Infinity) {
    calculatedModel.value.questionnaire.maxExpectedVolume = undefined;
  }

  return requestManager
    .cancelAndNew(
      'updateComplianceCase',
      services.compliance.updateClientComplianceCase(authStore.loggedInIdentity!.client!.id, {
        questionnaire: { ...calculatedModel.value.questionnaire },
      })
    )
    .toPromise();
}

function proceed() {
  savePermissions()
    .then(saveComplianceCase)
    .then(() => {
      emit('update:model', calculatedModel.value);
      emit('proceed');
    });
}

watch(
  () => [tradingProfileFM.form.sourceOfFundsSelection, tradingProfileFM.form.occupationSelection],
  () => {
    const sourceOfFundsField = getChildModel(tradingProfileFM.form, 'sourceOfFunds');
    const occupationField = getChildModel(tradingProfileFM.form, 'occupation');

    if (sourceOfFundsField) {
      const isOther = tradingProfileFM.form.sourceOfFundsSelection === sourceOfFundsLabels.OTHER;
      setState(sourceOfFundsField, 'required', isOther);
      setState(sourceOfFundsField, 'hidden', !isOther);
      if (tradingProfileFM.validation?.sourceOfFunds) {
        tradingProfileFM.validation.sourceOfFunds.$reset();
      }
    }
    if (occupationField) {
      const isOther = tradingProfileFM.form.occupationSelection === occupationLabels.OTHER;
      setState(occupationField, 'required', isOther);
      setState(occupationField, 'hidden', !isOther);
      if (tradingProfileFM.validation?.occupation) {
        tradingProfileFM.validation.occupation.$reset();
      }
    }
  },
  { immediate: true }
);

watch(
  () => props.model,
  () => {
    updateModel(tradingProfileFM.form, props.model.questionnaire ?? {});

    if (props.model.questionnaire?.minExpectedVolume === 10000001) {
      tradingProfileFM.form.maxExpectedVolume = Infinity;
    }

    const sourceOfFunds = (props.model.questionnaire as CompanyQuestionnaire)?.sourceOfFunds ?? null;
    if (sourceOfFunds) {
      if (!sourceOfFundOptions.find((o) => o.value === sourceOfFunds)) {
        tradingProfileFM.form.sourceOfFundsSelection = SourceOfFunds.OTHER;
      } else {
        tradingProfileFM.form.sourceOfFundsSelection = sourceOfFunds;
        tradingProfileFM.form.sourceOfFunds = '';
      }
    }

    const occupation = (props.model.questionnaire as IndividualQuestionnaire)?.occupation ?? null;
    if (occupation) {
      if (!occupationOptions.find((o) => o.value === occupation)) {
        tradingProfileFM.form.occupationSelection = Occupation.OTHER;
      } else {
        tradingProfileFM.form.occupationSelection = occupation;
        tradingProfileFM.form.occupation = '';
      }
    }
  },
  { immediate: true }
);
</script>

<template>
  <div x-test-name="trading-profile-form">
    <h2 class="mb-0">Trading Details and Permissions</h2>
    <p>Please select the Foreign Exchange products that you are interested in&ast;</p>
    <ValidatedForm :fm="tradingProfileFM.form" :validation.sync="tradingProfileFM.validation">
      <template #tradingFM.emirClassification:label>
        <div id="emirClassificationForm" class="d-inline-flex align-items-center w-100">
          EMIR Classification
          <IconAlertCircle v-b-tooltip="emirClassificationTooltip" class="icon ml-2" />
        </div>
      </template>
      <template #tradingFM.beneficiaryCountries:label>
        <div>Destination of Payments&ast;</div>
        <div class="destination-sub-label mb-2">Select up to 5 countries</div>
      </template>
    </ValidatedForm>
    <OBOSettingsForm v-if="allowedToSetPermissions" :permissions.sync="permissions" :validation.sync="oboValidation" />
    <div class="buttons-holder">
      <VButton @click="proceed" :loading="requestManager.anyPending" :disabled="validation && validation.$invalid">
        Continue
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}

.destination-sub-label {
  font-size: $font-size-sm;
  font-weight: $font-weight-regular;
  color: $darkGrey;
}

::v-deep #emirClassificationForm {
  .tooltip-inner {
    max-width: 400px;
    @include themedBackgroundColor($color-primary);
  }
  .arrow::before {
    @include themedPropColor('border-top-color', $color-primary);
  }
  svg.icon {
    @include themedTextColor($color-primary);
  }
}
</style>
