<script lang="ts" setup>
import {
  DocumentExportOptions,
  DocumentExportFormat,
  NotificationEventType,
  NotificationType,
  VanillaPriceResponse,
} from 'ah-api-gateways';
import { downloadBlob } from 'ah-common-lib/src/helpers/download';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { reactive, computed, watch } from 'vue';
import { useAhTradesState } from '..';
import { mergeMap, filter, take, map } from 'rxjs/operators';

const props = defineProps<{ solution: VanillaPriceResponse }>();

const tradeState = useAhTradesState();

const exportOptionsParam: DocumentExportOptions = {
  fileFormat: DocumentExportFormat.PDF,
  documentTitle: 'Termsheet',
  backgroundExport: true,
};

const emit = defineEmits<{
  (e: 'error', value: any): void;
}>();

const notificationsStore = tradeState.store.useNotificationsStore();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadTermsheet') {
      loadSolutionTermsheet();
    }
  },
}).manager;

const state = reactive<{
  termsheetUrl: string | null;
  file: Blob | null;
}>({
  termsheetUrl: null,
  file: null,
});

const hasLoadError = computed(() => requestManager.requestStates.loadTermsheet === 'error');

const isSafari = computed(() => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
});

const termsheetIframeUrl = computed(() => `${state.termsheetUrl}${isSafari.value ? '' : '#toolbar=0'}`);

/**
 * This function is requesting a vanilla options termsheet.
 * After the request is made we are watching the notificationEvents until we receive a notification saying that the file is ready.
 * We use the data received by the notification center to then synchronously request the document.
 */
function loadSolutionTermsheet() {
  requestManager
    .sameOrCancelAndNew(
      'loadTermsheet',
      tradeState.services.termsheetService
        .exportVanillaOptionsTermsheet(props.solution.id, props.solution.premiumCurrency!, exportOptionsParam)
        .pipe(
          mergeMap((doc) =>
            // We filter the notifications by resource id (as to match the document id) and with the correct types that enable download
            notificationsStore.notificationEvents.pipe(
              filter(
                (notif) =>
                  notif.eventType === NotificationEventType.NOTIFICATION_CREATED &&
                  notif.payload.resource === doc.id &&
                  notif.payload.type === NotificationType.DOCUMENT_STATUS_EXPORTED
              ),
              // take returns an Observable that emits only the first count values
              take(1),
              // returns the notification
              map(() => doc)
            )
          ),
          mergeMap((doc) =>
            // request the document with the data received by the notification center
            tradeState.services.documents.getSyncDocument(doc.type, doc.id, {
              axiosConfig: { headers: { Accept: 'application/pdf' } },
            })
          )
        )
    )
    .subscribe(
      (doc) => {
        state.file = new Blob([doc.data as any as BlobPart], { type: 'application/pdf' });
        state.termsheetUrl = URL.createObjectURL(state.file);
      },
      (e) => {
        emit('error', e);
      }
    );
}

watch(
  () => props.solution,
  () => loadSolutionTermsheet(),
  { immediate: true }
);

function downloadPDF() {
  if (state.file) {
    downloadBlob(state.file, 'termsheet.pdf');
  }
}
</script>

<template>
  <div class="termsheet-wrapper">
    <object
      v-if="state.termsheetUrl"
      type="application/pdf"
      class="termsheet-pdf"
      :data="termsheetIframeUrl"
      frameborder="0"
    >
      <VRow class="text-center h-100" align-h="center" align-v="center">
        <VCol class="px-5">
          <NotFoundIcon class="error-icon" />
          <p>It seems like your device can't show this file properly.</p>
          <VButton @click="downloadPDF"> Download termsheet </VButton>
        </VCol>
      </VRow>
    </object>
    <div v-else-if="requestManager.anyPending" class="loading">
      <LoadingIcon class="loading-icon" />
      <p>Loading termsheet...</p>
    </div>
    <div v-else-if="hasLoadError" class="error">
      <p>
        Failed to load termsheet <br />
        <a class="link" @click="loadSolutionTermsheet">Retry</a>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.termsheet-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    padding-bottom: math.div($padded-space, 2);

    @include tablet {
      padding-bottom: $padded-space;
    }
  }

  .termsheet-pdf {
    width: 100%;
    flex-grow: 1;
  }

  .loading,
  .error {
    padding: 1rem 0;
    text-align: center;

    .loading-icon {
      display: inline-block;
      font-size: 3rem;
    }
  }

  .error-icon {
    font-size: 50px;
    margin-bottom: 2rem;
  }
}
</style>
