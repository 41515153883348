/* eslint-disable no-param-reassign */
import _Vue, { PluginObject } from 'vue';
import OtpModalComponent from './OtpModalComponent.vue';
import { Observable } from 'rxjs';
import { VueConstructor } from 'vue/types/umd';
import { AxiosError } from 'axios';
import { SecurityErrorCodes } from 'ah-api-gateways';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';

export interface OtpConfig {
  modalComponent?: VueConstructor;
  refreshOtpRequest: () => Observable<ExpiryTime>;
  otpErrorCheck?: (error: AxiosError) => boolean;
  staticOTP?: boolean;
}

export interface OtpOptions<T = any> {
  request: (otp: string) => Observable<T>;
  refreshOtpRequest?: () => Observable<void>;
  otpPhone?: string;
  otpErrorCheck?: (error: AxiosError) => boolean;
  staticOTP?: boolean;
}

export interface OtpObj {
  requestOtp: <T = any>(options: OtpOptions<T>) => Observable<T>;
  clear: () => void;
}

const defaultOtpErrorCheck = (error: AxiosError) => error.response?.data?.code === SecurityErrorCodes.INVALID_OTP;

export default {
  install: function install(Vue: typeof _Vue, config: OtpConfig) {
    const otpRequests: { component: any; teardown: () => void }[] = [];

    function getOtp<T = string>(options: OtpOptions<T>) {
      return new Observable<T>((sub) => {
        // Typing `as any` as using `defineEmits` seems to throw typings off - this should no longer be an issue in Vue 3
        const OtpModalComponentConstructor = Vue.extend(OtpModalComponent as any);

        const constructor: VueConstructor<_Vue> = config.modalComponent ?? OtpModalComponentConstructor;
        const component = new constructor({
          propsData: {
            otpPhone: options.otpPhone,
            staticOTP: options.staticOTP,
            request: options.request,
            refreshOtpRequest: options.refreshOtpRequest || config.refreshOtpRequest,
            otpErrorCheck: options.otpErrorCheck || defaultOtpErrorCheck,
          },
        });

        component.$on('cancel', () => {
          sub.error('OTP_CANCELLED');
          sub.complete();
        });

        component.$on('error', (error: any) => {
          sub.error(error);
          sub.complete();
        });

        component.$on('completed', (result: T) => {
          sub.next(result);
          sub.complete();
        });

        component.$mount();
        document.body.appendChild(component.$el);

        const otpRequest = {
          component,
          teardown: () => {
            component.$destroy();
            const index = otpRequests.indexOf(otpRequest);
            if (index > -1) {
              otpRequests.splice(index, 1);
            }
          },
        };

        otpRequests.push(otpRequest);

        (component as any).show();
        return otpRequest.teardown;
      });
    }

    const otpObj: OtpObj = {
      clear() {
        otpRequests.forEach((r) => r.teardown());
      },
      requestOtp(options) {
        return getOtp(options);
      },
    };

    Vue.otp = otpObj;
    Vue.prototype.$otp = otpObj;
  },
} as PluginObject<OtpConfig>;
