<script setup lang="ts">
import { reactive, ref, PropType, onBeforeMount, computed, watch } from 'vue';
import { makeFormModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import BeneficiaryAccountSelector from 'ah-beneficiaries/src/components/BeneficiaryAccountSelector.vue';
import BeneficiaryEditModal from 'ah-beneficiaries/src/components/BeneficiaryEditModal.vue';
import { TradeFundsDestination, TradeDetails } from '../../models/trade';
import { Beneficiary, AuthorityType, Permission, getBuyCcy, AmountType } from 'ah-api-gateways';
import WalletTransferText from 'ah-wallets/src/components/WalletTransferText.vue';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { TradeDestination } from './tradeDestination';
import IconAlertCircle from 'ah-common-lib/src/icons/components/IconAlertCircle.vue';
import { PaymentLimitData, BankingScheme, BeneficiaryCategory, DrawdownDetails } from 'ah-api-gateways/models';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { DrawdownQuotePriceResponse } from 'ah-api-gateways/models';
import AggregatedLimitErrorMessage from '../../../../ah-payments/src/components/AggregatedLimitErrorMessage.vue';
import { useAggregatedPaymentLimitChecker } from '../../composables/aggregatedPaymentLimitChecker';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useAhTradesState } from '../..';
import { ValidatedForm } from 'ah-common-lib/src/form/components';

const props = defineProps({
  /**
   * Trade details object (managed by TradeDetailsForm)
   */
  tradeDetails: {
    type: Object as PropType<TradeDetails | DrawdownDetails | null>,
    default: null,
  },
  /**
   * Trade price request and response (as received by getting/refreshing  the trade)
   */
  tradePrice: {
    type: Object as PropType<DrawdownQuotePriceResponse | null>,
    default: null,
  },
  /**
   * Possible beneficiary target of the trade
   *
   * synchronizable via `update:beneficiary` or .sync modifier
   */
  beneficiary: {
    type: Object as PropType<Beneficiary>,
  },

  /**
   * Trade destination object (managed by TradeDestinationForm)
   *
   * synchronizable via `update:tradeDestination` or .sync modifier
   */
  tradeDestination: {
    type: Object as PropType<TradeDestination | null>,
    default: null,
  },
  /**
   * Whether to allow keeping funds
   */
  allowKeepFunds: {
    type: [Boolean, String],
    default: true,
  },
  /**
   * Whether to allow sending money
   */
  allowSendMoney: {
    type: [Boolean, String],
    default: true,
  },
});

const emit = defineEmits({
  'update:tradeDestination': (_model: TradeDestination) => true,
});

/**
 * Trade Destination form
 *
 * Uses Authorities to determine if choosing "SEND" is allowed
 */
const destinationOptions: FieldOptionObj[] = [
  { label: 'Keep funds', value: TradeFundsDestination.KEEP },
  { label: 'Make payment', value: TradeFundsDestination.SEND },
];

const fundsFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'fundsForm',
    state: {
      class: 'form-narrow margin-auto',
    },
    fieldType: 'form',
    fields: [
      radioField('destination', '', destinationOptions, {
        defaultValue: TradeFundsDestination.SEND,
        inline: true,
      }),
    ],
  }),
  validation: null,
});

const requestManager = useRequestManager({
  exposeToParent: ['getClientPermissions'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'getClientPermissions') {
      checkUserPermissions();
    }
  },
}).manager;

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useAhTradesState();

const { aggregatedLimitForCurrency, aggregatedPaymentReviewRoute, isAggregatedLimitReached, loadAggregatedLimit } =
  useAggregatedPaymentLimitChecker({
    requestManager: requestManager,
    services: tradeState.services,
    data: {
      clientId: computed(
        () => onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id
      ),
      oboClientId: computed(() => onBehalfOfClient.value?.id),
      paymentCurrency: computed(() => props.tradeDetails?.buyCurrency),
      paymentAmount: computed(() => props.tradePrice?.ccy1.clientAmount),
    },
  });

const beneficiarySelector = ref<InstanceType<typeof BeneficiaryAccountSelector>>();

const beneficiaryQuery = ref<string>('');

const clientPermissions = ref<Permission[]>([]);

const currentPaymentLimit = ref<PaymentLimitData>();

const hasSwiftBeneficiary = ref<boolean>(false);

const beneficiarySelected = ref<Beneficiary>();

onBeforeMount(() => {
  if (!props.tradeDestination) {
    emitDestinationChange(false);
  }
});

const canManageBeneficiaries = computed(
  () => onBehalfOfClient.value || tradeState.store.useAuthStore().hasAuthorities(AuthorityType.MANAGE_BENEFICIARIES)
);

const displayWarning = computed(
  () => props.tradeDetails && currentPaymentLimit.value && beneficiarySelected.value && !beneficiaryIsSwift.value
);

const beneficiaryIsSwift = computed(() => {
  if (beneficiarySelected.value) {
    return beneficiarySelected.value.bankingScheme === BankingScheme.SWIFT;
  }

  return false;
});

/**
 * Send Money is allowed for Trader OBO (Admin users), otherwise depends on OBO permissions
 *
 * Can be forced false via prop `allowSendMoney`
 */
const sendMoneyAllowed = computed(() => {
  if (props.allowSendMoney === false) {
    return false;
  }
  if (tradeState.store.useAuthStore().isAHUser) {
    return true;
  }
  if (onBehalfOfClient.value && clientPermissions.value.length > 0) {
    const permission = clientPermissions.value.find((p) => p.authority === AuthorityType.PAY_ON_BEHALF_OF);
    return permission?.allow ?? false;
  }
  return tradeState.store.useAuthStore().hasAuthorities(AuthorityType.MAKE_PAYMENTS);
});

const buyCcy = computed(() => {
  if (props.tradePrice) {
    return getBuyCcy(props.tradePrice);
  }

  return undefined;
});

const isPaymentOverLimit = computed(() => {
  if (!currentPaymentLimit.value?.hasLimit || !props.tradeDetails?.amount) return false;
  if (props.tradeDetails.amountType === AmountType.BUY) {
    return props.tradeDetails.amount > currentPaymentLimit.value.amountLimit;
  }
  const buyPrice =
    props.tradePrice?.ccy1.amountType === AmountType.BUY ? props.tradePrice?.ccy1 : props.tradePrice?.ccy2;

  return buyPrice && buyPrice.clientAmount > currentPaymentLimit.value.amountLimit;
});

const isPaymentInvalid = computed(() => {
  if (props.tradeDestination?.destination === TradeFundsDestination.KEEP) return false;
  else if (!props.tradeDestination?.beneficiary) return true;
  return isPaymentOverLimit.value && props.tradeDestination.beneficiary.bankingScheme !== BankingScheme.SWIFT;
});

const shouldAllowKeepFunds = computed(() => props.allowKeepFunds !== false);

const validDestination = computed(() => {
  if (fundsFormDef.form.destination === TradeFundsDestination.SEND) {
    return (
      (!!beneficiarySelected.value ?? !!props.beneficiary ?? !!props.tradeDestination?.beneficiary) &&
      !isAggregatedLimitReached.value
    );
  }
  return true;
});

const isTradeDestinationSend = computed(() => props.tradeDestination?.destination === TradeFundsDestination.SEND);

const showAggregatedLimitErrorMessage = computed(() => isTradeDestinationSend.value && isAggregatedLimitReached.value);

function checkUserPermissions() {
  if (onBehalfOfClient.value) {
    requestManager
      .currentOrNew('getClientPermissions', tradeState.services.authz.getClientsPermissions(onBehalfOfClient.value.id))
      .subscribe((response) => (clientPermissions.value = response));
  }
}
watch(onBehalfOfClient, checkUserPermissions, { immediate: true });

function onSendMoneyAllowedChange() {
  const destinationField = getChildModel(fundsFormDef.form, 'destination');
  if (props.allowKeepFunds !== false && !props.tradeDestination) {
    fundsFormDef.form.destination = TradeFundsDestination.KEEP;
  }

  let options = destinationOptions;
  options = options.map((i) => ({
    ...i,
    disabled: i.value === TradeFundsDestination.SEND ? !sendMoneyAllowed.value : !shouldAllowKeepFunds.value,
  }));

  if (destinationField) {
    setState(destinationField, 'options', options);
    fundsFormDef.form.destination = options.find((opt) => !opt.disabled)?.value ?? null;
  }
  emitDestinationChange();
}

watch([shouldAllowKeepFunds, sendMoneyAllowed], onSendMoneyAllowedChange, { immediate: true });

function onTradeDetailsChange() {
  emitDestinationChange(false);
}

watch(() => [props.tradeDetails, buyCcy.value], onTradeDetailsChange);

function onBuyCurrencyChange() {
  const query: any = {};
  query.category = BeneficiaryCategory.CLIENT_3RD_PARTY;
  query.clientId = onBehalfOfClient.value?.id || tradeState.store.useAuthStore().loggedInIdentity!.client!.id;
  query.pageSize = 1;
  query.bankingScheme = BankingScheme.SWIFT;
  query.currency = props.tradeDetails?.buyCurrency;

  if (isTradeDestinationSend.value) {
    loadAggregatedLimit();
  }

  requestManager
    .sameOrCancelAndNew('getSwiftBeneficiaries', tradeState.services.beneficiary.listBeneficiaries(query))
    .subscribe((response) => {
      hasSwiftBeneficiary.value = response.total >= 1;
    });
}

watch(() => props.tradeDetails?.buyCurrency, onBuyCurrencyChange, { immediate: true });

function onTradeDestinationChange() {
  if (props.tradeDestination) {
    fundsFormDef.form.destination = props.tradeDestination.destination;
    beneficiaryQuery.value = props.tradeDestination.beneficiaryQuery ?? '';
  }
}

watch(() => props.tradeDestination, onTradeDestinationChange, { immediate: true });

watch(
  () => props.tradeDestination?.destination,
  () => {
    if (isTradeDestinationSend.value) {
      loadAggregatedLimit();
    }
  },
  { immediate: true }
);

function onTradeChange(newDetails: TradeDetails, oldDetails: TradeDetails) {
  if (props.tradeDetails && newDetails.sellCurrency !== oldDetails?.sellCurrency) {
    tradeState.store
      .useSettingsStore()
      .loadPaymentLimit({
        force: false,
        currency: props.tradeDetails!.buyCurrency,
        bankingScheme: BankingScheme.LOCAL,
      })
      .then((response) => (currentPaymentLimit.value = response));
  }
}

watch(
  () => [props.tradeDetails, props.tradeDestination],
  (newVal: any, oldVal: any) => onTradeChange(newVal, oldVal),
  { immediate: true }
);

function emitDestinationChange(dirty = true, beneficiary?: Beneficiary | null) {
  const out: TradeDestination = {
    ...props.tradeDestination,
    beneficiary: beneficiarySelected.value ?? beneficiary ?? props.tradeDestination?.beneficiary ?? null,
    beneficiaryQuery: beneficiaryQuery.value,
    reason: props.tradeDestination?.reason,
    reference: props.tradeDestination?.reference,
    destination: props.beneficiary ? TradeFundsDestination.SEND : fundsFormDef.form.destination,
    dirty,
    valid: validDestination.value,
  };

  // if beneficiary is set to null we want to unselect the beneficiary forcefully
  if (beneficiary === null) {
    delete (out as any).beneficiary;
  }

  if (
    fundsFormDef.form.destination === TradeFundsDestination.KEEP ||
    props.tradeDetails?.buyCurrency !== out.beneficiary?.currency
  ) {
    out.beneficiary = null;
  }

  emit('update:tradeDestination', out);
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    emitDestinationChange();
  }
}

function onAccountSelected(beneficiary?: Beneficiary | null) {
  beneficiarySelected.value = !beneficiary ? undefined : beneficiary;
  emitDestinationChange(props.tradeDestination?.dirty, beneficiary);
}

function onBeneficiaryAdded() {
  beneficiarySelector.value?.onBeneficiaryAdded();
}

defineExpose({ isPaymentInvalid });
</script>

<template>
  <div>
    <div v-if="!beneficiary">
      <h3>Do you want to hold the converted funds in your wallet or make a payment with it?</h3>

      <div class="funds-form-wrapper clearfix">
        <ValidatedForm
          class="float-left"
          :fm="fundsFormDef.form"
          :validDestination.sync="fundsFormDef.validation"
          @form-event="onFormEvent"
        />
        <InfoTooltip
          class="float-left"
          :text="`<p>Make payment: ${$ahTradesState.i18n.t(
            'tooltips.sendFunds'
          )}</p><p>Keep funds: ${$ahTradesState.i18n.t('tooltips.keepFunds')}</p>`"
        />
      </div>
      <AggregatedLimitErrorMessage
        v-if="showAggregatedLimitErrorMessage && aggregatedLimitForCurrency"
        :aggregatedLimitForCurrency="aggregatedLimitForCurrency"
      >
        <template #route>
          <span v-if="onBehalfOfClient?.id">please go to the payments page</span>
          <RouterLink v-else :to="aggregatedPaymentReviewRoute">please click here</RouterLink>
        </template>
      </AggregatedLimitErrorMessage>
    </div>

    <ExpandTransition>
      <VRow class="trade-destination-form-error" v-if="isPaymentOverLimit">
        <VCol class="icon-wrapper" cols="0" v-if="displayWarning">
          <IconAlertCircle />
        </VCol>
        <VCol v-if="displayWarning">
          <h3 class="mb-0" v-if="tradeDetails && currentPaymentLimit">
            Payment amount exceeds {{ tradeDetails.buyCurrency }}
            {{ formatCurrencyValue(currentPaymentLimit.amountLimit) }}.
          </h3>
          <div v-if="hasSwiftBeneficiary" class="text-small">Payments above this amount can only be paid by SWIFT.</div>
          <div v-else class="text-small">
            Payments above this amount can only be paid by SWIFT. Please create a new SWIFT beneficiary
            <BeneficiaryEditModal
              v-slot="{ showModal }"
              :forceBankingScheme="BankingScheme.SWIFT"
              @update:beneficiary="onBeneficiaryAdded"
              v-if="canManageBeneficiaries"
            >
              <p class="link d-inline" @click="showModal">here</p>
              .
            </BeneficiaryEditModal>
          </div>
        </VCol>
      </VRow>
    </ExpandTransition>

    <!-- send funds -->
    <div v-if="fundsFormDef.form.destination === TradeFundsDestination.SEND" class="d-flex flex-column mt-2">
      <!-- beneficiary table -->
      <div class="col col-12 mb-2">
        <label> Select Beneficiary</label>
        <InfoTooltip class="pb-1" :text="$ahBeneficiariesState.i18n.t('tooltips.beneficiary')" />
      </div>
      <BeneficiaryAccountSelector
        ref="beneficiarySelector"
        :allowedCurrencies="tradeDetails ? [tradeDetails.buyCurrency] : undefined"
        :beneficiary="tradeDestination && tradeDestination.beneficiary"
        :beneficiaryQuery.sync="beneficiaryQuery"
        @update:beneficiary="onAccountSelected"
      />
    </div>

    <!-- keep funds -->
    <div v-else-if="tradeDetails" class="mt-4">
      <WalletTransferText
        v-if="buyCcy"
        :destinationAmount="buyCcy.clientAmount"
        :destinationCurrency="buyCcy.currency"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.trade-destination-form-error > * {
  @include themedTextColor($color-danger, $color-dark-danger);

  &.icon-wrapper {
    margin-top: -0.5rem;
    font-size: 1.8rem;
  }
}
</style>
