<script setup lang="ts">
import { ClientRiskCollateralProfile } from 'ah-api-gateways';
import { computed, PropType } from 'vue';
import FormattedCurrency from '../limitsAndCollateral/FormattedCurrency.vue';

/**
 * This component renders a utilization bar that visually represents the
 * client's collateral profile data, including Total Mark to Market (MTM) and
 * Variation Margin (VM) Limit. Also it has a zero marker for reference, so to the left of that
 * we have negative values and to the right we have positive values.
 * So if:
 *  - VM Limit is greater than MTM -> we will have only two zones: yellow and green
 *    - green zone - will always be 50%
 *    - yellow (warning) zone - will be also 50% and the VM Limit marker will be at the end, since it is the greater value
 *  - VM Limit is lesser than MTM -> we will have three zones: red, yellow and green
 *    - green zone - will always be 50%
 *    - yellow zone - since MTM is the greater value it means that it will represent 50%, so we need to calculate
 *    the warning zone based on that value (warningZonePercentage)
 *    - red zone - will be always 50% minus the yellow zone percentage
 **/

const props = defineProps({
  collateralProfileData: { type: Object as PropType<ClientRiskCollateralProfile>, required: true },
  inheritedHeight: {
    type: Number,
    required: false,
  },
});

const isVMLimitGreaterThenMTM = computed(
  () => props.collateralProfileData.variationMarginAmount > Math.abs(props.collateralProfileData.totalMarkToMarket)
);

const warningZonePercentage = computed(() =>
  isVMLimitGreaterThenMTM.value
    ? 50
    : (props.collateralProfileData.variationMarginAmount * 50) / Math.abs(props.collateralProfileData.totalMarkToMarket)
);

const dangerZonePercentage = computed(() => 50 - warningZonePercentage.value);

/*
  The total bar has 100%, so on the left side of zero we have 50% and the same for the right side.
  To place the MTM marker correctly, since we are reading from left to right,
  meaning 0% on the left and 100% on the right, when the MTM value is negative we need to calculate the %
  inside of the negative 50% and then sum 50% to display the MTM market to the zero marker's left side (negative values)
 */
const mtmPercentage = computed(() => {
  if (isVMLimitGreaterThenMTM.value) {
    return (
      (props.collateralProfileData.totalMarkToMarket * 50) / props.collateralProfileData.variationMarginAmount + 50
    );
  } else {
    return props.collateralProfileData.totalMarkToMarket < 0 ? 0 : 100;
  }
});
</script>

<template>
  <div class="utilization-bar-holder" :style="{ height: inheritedHeight + 'px' }">
    <div class="utilization-bar-holder-inner">
      <div class="mtm-marker" :style="{ left: `${mtmPercentage}%` }">
        <span
          class="mtm-marker-label"
          :class="{ 'mtm-extreme-left': mtmPercentage <= 10, 'mtm-extreme-right': mtmPercentage >= 90 }"
        >
          MTM
          <FormattedCurrency
            class="bold"
            :value="collateralProfileData.totalMarkToMarket"
            :prefix-label="collateralProfileData.currency"
            :main-font-size="14"
            :decimal-font-size="10"
          />
        </span>
        <div class="mtm-bullet"></div>
      </div>
      <div class="zero-marker">
        <span class="zero-marker-label bold"> 0 </span>
      </div>
      <div
        class="utilization-bar"
        :style="`grid-template-columns: ${dangerZonePercentage}% ${warningZonePercentage}% 50%`"
      >
        <div class="utilization-item danger-utilization"></div>
        <div class="utilization-item warning-utilization">
          <div class="vm-marker">
            <span class="vm-marker-label" :class="{ 'vm-extreme-left': warningZonePercentage >= 40 }"
              >VM Limit ({{ (collateralProfileData.variationMarginPercentage * 100).toFixed(2) }}%)
              <FormattedCurrency
                class="bold"
                :value="collateralProfileData.variationMarginAmount"
                :prefix-label="collateralProfileData.currency"
                :main-font-size="14"
                :decimal-font-size="10"
            /></span>
            <div class="vm-bullet"></div>
          </div>
        </div>
        <div class="utilization-item normal-utilization"></div>
        <div
          v-for="item in Array.from({ length: 10 }, (_v, i) => i)"
          :key="item"
          :class="{ 'fixed-markers': item !== 0 && item !== 5 }"
          :style="{ left: `${item * 10}%` }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
/**
 * FIXME this hardcoded (#116466, #4cefc3, #ff9900 and #e11d48) colors will live here for now, since they are only used in here
 * and we don't want those as theme colors. In the future we will move this into a proper file.
 */
.bold {
  font-weight: $font-weight-bold;
}
.utilization-bar-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3em 0.5em 1em;
  margin: 0.5em 0;

  .utilization-bar-holder-inner {
    position: relative;
  }

  .vm-marker {
    position: absolute;
    height: 2em;
    top: 0;
    border-right-width: 1px;
    border-right-style: solid;

    .vm-marker-label {
      position: absolute;
      left: -2em;
      margin-left: -0.5em;
      top: 2.5em;
      font-size: 0.8em;
      width: max-content;

      &.vm-extreme-left {
        left: 0;
      }
    }

    .vm-bullet {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #116466;
      border: 1px solid white;
      border-radius: 50%;
      top: -0.2em;
      left: -0.25em;
    }
  }

  .mtm-marker {
    position: absolute;
    height: 2em;
    top: -2em;
    border-right-width: 1px;
    border-right-style: solid;

    .mtm-marker-label {
      position: absolute;
      width: max-content;
      left: -5em;
      top: -2em;
      font-size: 0.8em;

      &.mtm-extreme-left {
        left: -0.5em;
      }

      &.mtm-extreme-right {
        left: auto;
        right: -0.5em;
      }
    }
    .mtm-bullet {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #116466;
      border: 1px solid white;
      border-radius: 50%;
      top: 1.8em;
      left: -0.29em;
      z-index: 2;
    }
  }

  .zero-marker {
    position: absolute;
    height: 1em;
    top: 0;
    left: 49.9%;
    border-right-width: 1px;
    border-right-style: solid;
    z-index: 2;

    .zero-marker-label {
      position: absolute;
      left: -0.35em;
      top: 1.25em;
      font-size: 0.7em;
    }
  }

  .utilization-bar {
    display: grid;
    margin-bottom: 2em;

    .utilization-item {
      height: 4px;
      position: relative;
      z-index: 1;
    }

    .fixed-markers {
      position: absolute;
      height: 0.5em;
      top: 0;
      border-right-width: 1px;
      border-right-style: solid;
      z-index: 0;
    }

    .normal-utilization {
      background-color: #4cefc3;
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
    }

    .warning-utilization {
      background-color: #ff9900;
    }

    .danger-utilization {
      background-color: #e11d48;
      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
    }
  }
}
</style>
