<script setup lang="ts">
import { ClientRiskCollateralProfile } from 'ah-api-gateways';
import { computed, PropType, ref, onMounted, nextTick, onBeforeUnmount } from 'vue';
import { formatNumberValue } from 'ah-common-lib/src/helpers/numbers';
import { getChartColorsForProfileUsageLevel } from '../../helpers/chartConfig';
import SimpleDoughnutChart from 'ah-reports/src/components/SimpleDoughnutChart.vue';
import FormattedCurrency from '../limitsAndCollateral/FormattedCurrency.vue';

const props = defineProps({
  collateralProfileData: { type: Object as PropType<ClientRiskCollateralProfile>, required: true },
});

const creditInfo = computed(() => [
  { label: 'Variation Margin Limit', value: props.collateralProfileData.variationMarginAmount },
  { label: 'Your Mark-to-Market Position', value: props.collateralProfileData.totalMarkToMarket },
  { label: 'Posted Collateral', value: props.collateralProfileData.totalPostedCollateral },
  { label: 'IM Requirement', value: props.collateralProfileData.totalInitialMarginCollateralFunding },
]);

/**
 * The code bellow has the purpose to get the component's height and pass it to the parent
 * This will guarantee that the Your Mark-to-Market Position's component always has the same height that this one
 */
const component = ref<InstanceType<typeof HTMLElement>>();

const emit = defineEmits<{ (e: 'height-updated', height: number): void }>();

const totalCreaditAmountInUse = computed(() => {
  if (props.collateralProfileData.creditUsage < 0) {
    return 0;
  } else if (props.collateralProfileData.creditUsage > 1) {
    return 1;
  }

  return props.collateralProfileData.creditUsage;
});

const totalCreaditAmountRemaining = computed(() => 1 - totalCreaditAmountInUse.value);

const updateHeight = () => {
  if (component.value) {
    const componentHeight = component.value.clientHeight;
    emit('height-updated', componentHeight);
  }
};

const handleResize = () => {
  nextTick(updateHeight);
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  nextTick(updateHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <div class="d-flex chart-section" ref="component">
    <SimpleDoughnutChart
      :colors="getChartColorsForProfileUsageLevel(totalCreaditAmountInUse)"
      :fractions="[totalCreaditAmountInUse, totalCreaditAmountRemaining]"
      secondaryLabel="Utilised"
    >
      <template #mainLabel>
        <div>{{ formatNumberValue(totalCreaditAmountInUse * 100, 2) }}</div>
        <div style="font-size: medium" class="mt-2">%</div>
      </template>
    </SimpleDoughnutChart>

    <div class="chart-info">
      <p v-for="item in creditInfo" :key="item.label">
        {{ item.label }}:
        <strong>
          {{ collateralProfileData.currency }}
          <FormattedCurrency :value="item.value" :mainFontSize="16" :decimalFontSize="14" />
        </strong>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart-section {
  margin: 0.5em 0;

  h1 {
    margin-bottom: 1rem;
  }
  .chart-info {
    margin-left: 2rem;
    align-content: center;
    p {
      margin-bottom: 0.5rem;
    }
  }
}

::v-deep .doughnut-chart {
  width: 160px;
  height: 160px;
}
</style>
