<script setup lang="ts">
import { Client, Wallet } from 'ah-api-gateways';
import { RequestState } from 'ah-requests';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { ON_BEHALF_OF_CLIENT_INJECT_KEY } from 'ah-common-lib/src/onBehalfOf/constants';
import { inject, reactive, watch } from 'vue';
import { useAhWalletsState } from '..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const walletState = useAhWalletsState();

const requestManager = useRequestManager();

const props = withDefaults(
  defineProps<{
    destinationCurrency?: string;
    destinationAmount?: number;
  }>(),
  {
    destinationCurrency: '',
    destinationAmount: 0,
  }
);

const state = reactive<{
  onBehalfOfClient: Client | null;
  destinationWallet: Wallet | null;
  destinationWalletLoadState: RequestState;
}>({
  onBehalfOfClient: inject(ON_BEHALF_OF_CLIENT_INJECT_KEY, null),
  destinationWallet: null,
  destinationWalletLoadState: 'idle',
});

watch(
  () => props.destinationCurrency,
  (newVal?: string, oldVal?: string) => {
    if (newVal && newVal !== oldVal) {
      requestManager.manager
        .newPromise(
          'destinationWalletLoadState',
          walletState.store.useWalletsStore().loadCurrencyWallet({
            currency: newVal,
            owner: state.onBehalfOfClient ? { isPartner: false, id: state.onBehalfOfClient.id } : undefined,
          })
        )
        .then((wallet) => {
          state.destinationWallet = wallet || null;
        });
    }
  },
  { immediate: true }
);
</script>

<template>
  <div v-if="destinationCurrency">
    <p>
      <b v-if="destinationAmount > 0">{{ destinationCurrency }} {{ formatCurrencyValue(destinationAmount) }} </b>
      <span v-else>Funds</span> will be added to your {{ destinationCurrency }} wallet upon full and complete
      settlement.
    </p>
    <p class="text-secondary" v-if="state.destinationWalletLoadState !== 'pending' && !state.destinationWallet">
      A {{ destinationCurrency }} wallet will be automatically created and added to your account.
    </p>
  </div>
</template>
