import {
  userHasRole,
  partnerRoles,
  clientRoles,
  UserRole,
  ahRoles,
  Client,
  ClientType,
  ComplianceStatus,
  FullSessionUser,
  Individual,
  SessionUser,
  PublicComplianceCase,
  adminRoles,
  AuthorizationService,
  AuthenticationService,
  BrandingService,
  IndividualService,
  ClientService,
  ComplianceService,
  AuthorityType,
  UserStatus,
  SystemErrorCodes,
  GenericErrorCodes,
} from 'ah-api-gateways';
import { MINUTE } from 'ah-common-lib/src/constants/time';
import { parseJwt } from 'ah-common-lib/src/helpers/jwt';
import { SessionData } from 'ah-api-gateways';
import {
  CustomAxiosRequestConfig,
  HttpRequestOptions,
  HttpError,
  waitForEntityCreation,
  HttpService,
  SocketService,
  waitForEntityChange,
} from 'ah-requests';
import AlertModal from 'ah-common-lib/src/common/components/AlertModal.vue';
import { of, Subscription, from } from 'rxjs';
import { CachedItem } from 'ah-common-lib/src/helpers';
import { tap, mergeMap } from 'rxjs/operators';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { defineStore } from 'pinia';
import { StoreSupportData } from 'ah-common-lib/src/store';
import Router from 'vue-router';
import { Toast } from 'ah-common-lib/src/toast';
import { OtpObj } from 'ah-common-lib/src/otp';
import { ref } from 'vue';
import type { AppUpdater } from 'ah-app-updater';
import { cloneDeep } from 'lodash';

// Importing persisted state plugin for type completion
import 'pinia-plugin-persistedstate';
import { SyncMessage } from 'ah-common-lib/src/tabSync/syncMessage';

function makeCachedComplianceCase() {
  return new CachedItem<PublicComplianceCase>('authStoreComplianceCase', MINUTE * 15);
}

interface AuthSupportData {
  authRequestInterceptor: any;
  authResponseInterceptor: any;
  refreshTokenTimeout: number | null;
  activityMonitorSet: boolean;
  maintenanceCheckSub?: Subscription;
}

export type AuthStoreSupportData = StoreSupportData<
  AuthSupportData,
  {
    authz: AuthorizationService;
    auth: AuthenticationService;
    compliance: Pick<ComplianceService, 'getClientComplianceStatus' | 'getClientComplianceCase'>;
    http: HttpService;
    socket: SocketService;
    branding: BrandingService;
    individual: IndividualService;
    client: ClientService;
  }
>;

export type AuthStore = ReturnType<ReturnType<typeof authStoreFactory>>;

const REFRESH_SYNC_ACTION = 'REFRESH_SYNC_ACTION';

/**
 * Attempt to load impersonation token from the page's URL
 *
 * Simplified version of a query string parser to look for imp_token/r_token query string parameters,
 * and thus trigger the impersonation process
 */
function getImpersonateTokens() {
  function decode(str: string) {
    try {
      return decodeURIComponent(str);
    } catch (err) {
      // do nothing
    }
    return str;
  }

  const out = {
    token: '',
    refreshToken: '',
  };

  const queryVals = window.location.search.substr(1).split('&');

  queryVals.forEach((val) => {
    if (val.startsWith('token=')) {
      out.token = decode(val.substr(val.indexOf('=') + 1));
    }
    if (val.startsWith('r_token=')) {
      out.refreshToken = decode(val.substr(val.indexOf('=') + 1));
    }
  });

  return out;
}

export interface AuthStoreFactoryOptions {
  runActions: (action: commonStoreActions) => Promise<void>;
  checkRouteAuth: () => void;
  makeStoreSupportData: (init: AuthSupportData) => AuthStoreSupportData;
  getRouter: () => Router;
  getToast: () => Toast;
  getOtp: () => OtpObj;
  getAppUpdater: () => AppUpdater;
  admin: boolean;
  allowedRoles?: UserRole[];
}

export function authStoreFactory(options: AuthStoreFactoryOptions) {
  let sd!: AuthStoreSupportData;

  const REFRESH_TIMEOUT_DURATION = MINUTE * 10;

  let prevHasValidSession = false;
  let initial = true;

  function setupSd() {
    if (!sd) {
      sd = options.makeStoreSupportData({
        authRequestInterceptor: null,
        authResponseInterceptor: null,
        refreshTokenTimeout: null,
        activityMonitorSet: false,
        maintenanceCheckSub: undefined,
      });
    }
  }

  function checkSessionValidity(sessionUser: SessionUser) {
    if (!options.admin) {
      if (ahRoles.includes(sessionUser.role)) {
        throw 'ahAdminNotAllowed';
      } else if (options.allowedRoles && !options.allowedRoles.includes(sessionUser.role)) {
        throw 'userRoleNotAllowed';
      } else if (
        sessionUser.role !== UserRole.CLIENT_REGISTRATION &&
        !sessionUser.individual?.id &&
        !sessionUser.isOtpRequired
      ) {
        throw 'userWithoutIndividual';
      }
    } else {
      if (!ahRoles.includes(sessionUser.role)) {
        throw 'onlyAhAdminAllowed';
      }
    }
  }

  return defineStore('authModule', {
    persist: {
      beforeRestore: (context) => {
        if (prevHasValidSession !== context.store.hasValidSession) {
          prevHasValidSession = context.store.hasValidSession as boolean;
        }
      },
      afterRestore: (context) => {
        if (initial) {
          // There isn't a distiction between the first hydration and tab sync events, so we patch this with a `initial` value
          initial = false;
          return;
        }
        if (prevHasValidSession !== context.store.hasValidSession) {
          context.store.recheckAuth();
          context.store.recheckMaintenanceCheck();
        }
        if (!prevHasValidSession && context.store.hasValidSession) {
          options.runActions(commonStoreActions.onSessionStartOtherTab);
          if (context.store.isLoggedIn) {
            options.runActions(commonStoreActions.onLoginOtherTab);
          }
        }
        if (prevHasValidSession && !context.store.hasValidSession) {
          options.runActions(commonStoreActions.onLogoutOtherTab);
        }
        prevHasValidSession = context.store.hasValidSession as boolean;
      },
    },
    state: () => {
      setupSd();
      return {
        userData: null as FullSessionUser | null,
        sessionExpiry: -1 as number,
        refreshToken: null as any,
        authToken: null as any,
        impersonated: false as boolean,
        cachedComplianceCase: makeCachedComplianceCase(),
        termsAndConditionsAccepted: null as boolean | null,
        sessionAuthorities: new CachedItem<string[]>(),
        inMaintenance: false as boolean,
        lastLoginTimestamp: null as null | string,
      };
    },
    getters: {
      complianceStatus(state): ComplianceStatus | null {
        return state.cachedComplianceCase.item?.status || null;
      },
      complianceStatusReason(state): string | undefined {
        return state.cachedComplianceCase.item?.statusTransitionReason;
      },
      isImpersonated(state): boolean {
        return state.impersonated;
      },
      token(state): string {
        return state.authToken;
      },
      waitOnRequests() {
        return () => sd.reqManager.waitForRequests(undefined, true);
      },
      hasAuthorities(): (auth: string | string[], requireAll?: boolean) => boolean {
        return (auth: string | string[], requireAll = true) => {
          const authArr = Array.isArray(auth) ? auth : [auth];
          return requireAll
            ? !authArr.find((authority) => !this.authorities.includes(authority))
            : !!authArr.find((authority) => this.authorities.includes(authority));
        };
      },
      authorities(state) {
        return state.sessionAuthorities.item ?? [];
      },
      isAHAdminUser(state) {
        return !!state.userData && userHasRole(state.userData, UserRole.AH_ADMIN);
      },
      isUnverifiedSelfRegister(state) {
        return (
          !!state.userData &&
          state.userData.role === UserRole.CLIENT_REGISTRATION &&
          state.userData.status === UserStatus.TEMPORARY
        );
      },
      isClientUser(state) {
        return !!state.userData && userHasRole(state.userData, clientRoles);
      },
      isCompanyClient(state): any {
        return this.isClientUser && state.userData!.individual?.client?.type === ClientType.COMPANY;
      },
      isAdminUser(state) {
        return !!state.userData && userHasRole(state.userData, adminRoles);
      },
      isAgent(state) {
        return !!state.userData && userHasRole(state.userData, partnerRoles);
      },
      isAHUser(state) {
        return !!state.userData && userHasRole(state.userData, ahRoles);
      },
      questionnaireFinished(state): boolean {
        if (!this.isClientUser) return true;
        return !!state.cachedComplianceCase.item && state.cachedComplianceCase.item.status !== ComplianceStatus.PENDING;
      },
      isInMaintenance(state) {
        return state.inMaintenance;
      },
      /**
       * True is User is verified.
       *
       * A verified user MAY not have a Client or an Individual, as these only appear once the user has finished the registration.
       */
      isUserVerified(state): boolean {
        if (this.isAHUser) return true;
        if (!state.userData) return false;
        return state.userData.status === UserStatus.VERIFIED;
      },
      hasRegistrationSession(state): boolean {
        if (!state.userData) return false;
        return state.userData.role === UserRole.CLIENT_REGISTRATION;
      },
      /**
       * True is user has an OTP session
       */
      hasOTPSession(state): boolean {
        return !!state.userData?.isOtpRequired;
      },
      /**
       * True is user has a valid session
       * Any session not in a otp state is considered valid
       */
      hasValidSession(state): boolean {
        return !!state.userData && !state.userData.isOtpRequired;
      },
      /**
       * True if user is fully logged in meaning:
       *  - user has been verified
       *  - individual has been created
       *  - user is not in the OTP verification state
       */
      isLoggedIn(state): boolean {
        // FIXME as UserStatus.CREATED is deprecated, we consider it logged in for the purposes of Impersonation, to account for historical entries
        return (
          (this.isUserVerified || state.userData?.status === UserStatus.CREATED) &&
          !state.userData?.isOtpRequired &&
          !this.hasRegistrationSession
        );
      },
      termsAccepted(state): any {
        if (!this.isClientUser) return true;
        return !this.userData?.individual?.owner || state.termsAndConditionsAccepted === true;
      },
      loggedInUser(state): FullSessionUser | undefined {
        if (!state.userData) {
          return undefined;
        }
        return state.userData;
      },
      loggedInIdentity(): Individual | undefined {
        return this.loggedInUser?.individual;
      },
      loggedInRole(state): any {
        if (!state.userData) {
          return undefined;
        }
        return state.userData!.role;
      },
    },
    actions: {
      loadSessionAuthorities(payload?: { force: boolean; auth?: string; userId?: string }) {
        if (!payload?.userId && !this.userData?.id) {
          throw 'No user session available';
        }
        const options: HttpRequestOptions = { axiosConfig: {} };
        let userId = this.userData?.id || '';

        if (payload?.auth && payload?.userId) {
          options.axiosConfig.headers = {
            Authorization: `Bearer ${payload.auth}`,
          };

          userId = payload.userId;

          options.options = {
            skipAuth: true,
          };
        }
        return CachedItem.loadCachedItem(
          this.sessionAuthorities,
          sd.s.authz.getUserAuthorizedActions(userId, options),
          payload?.force
        );
      },
      async recheckAuth() {
        options.checkRouteAuth();
      },
      loadComplianceCase(payload?: { force?: boolean; session?: SessionData }): Promise<PublicComplianceCase> {
        const isClientUser = payload?.session
          ? userHasRole(payload.session.user || { role: '' as any }, clientRoles)
          : this.isClientUser;

        if (!isClientUser) {
          return Promise.resolve(this.cachedComplianceCase.item!);
        }

        const id = payload?.session?.user?.individual?.client?.id || this.loggedInIdentity!.client!.id;

        const options: HttpRequestOptions = { axiosConfig: {}, options: { errors: { silent: true } } };
        if (payload?.session) {
          options.axiosConfig.headers = {
            Authorization: `Bearer ${payload.session.token}`,
          };

          options.options = {
            skipAuth: true,
            errors: { silent: true },
          };
        }

        return CachedItem.loadCachedItem(
          this.cachedComplianceCase,
          waitForEntityCreation(() => sd.s.compliance.getClientComplianceCase(id, options)),
          payload?.force
        );
      },
      checkComplianceApproval(config: Partial<{ force: boolean; showModal?: boolean; client?: Client }> = {}) {
        if (!this.isClientUser && !config.client) {
          // if it's a partner, we don't make a compliance check
          return Promise.resolve(true);
        }
        if (config.client && !this.hasAuthorities(AuthorityType.ACT_ON_BEHALF_OF)) {
          options.getToast().error(`It seems that you don't have permissions to act on behalf of a client.`);
          return Promise.reject('Only Partners or admins can make trades on behalf of clients!');
        }

        const clientId = config.client?.id;
        const loadCase = clientId
          ? sd.reqManager
              .currentOrNew(
                `loadClientOnboardingStatus-${clientId}`,
                waitForEntityCreation(() =>
                  sd.s.compliance.getClientComplianceStatus(clientId, {
                    errors: { silent: true },
                  })
                )
              )
              .toPromise()
          : this.loadComplianceCase({ force: config.force });

        return loadCase
          .then((complianceCase) => complianceCase.status)
          .then(
            (status) => {
              if ([ComplianceStatus.APPROVED, ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS].includes(status)) {
                return true;
              }
              if (status === ComplianceStatus.UNKNOWN) {
                options.getToast().error('An unexpected problem has occurred. Please try again later.');
                throw new Error('Unexpected error');
              }
              if (config.showModal) {
                const component: AlertModal = new AlertModal({
                  propsData: {
                    modalTitle: 'Account approval pending',
                    modalText: `Your account is still pending approval, and you will be notified as soon as it is. <br/>You can navigate the platform until then, but you won't be able to perform any transactions, such as trading or payments.`,
                  },
                });

                component.$mount();
                (component as any).showModal();
                document.body.appendChild(component.$el);
              }
              throw new Error('Client is not approved!');
            },
            () => {
              options.getToast().error('An unexpected problem has occurred. Please try again later.');
              throw new Error('Unexpected error');
            }
          );
      },
      async setInterceptors() {
        // interceptor to add JWT token. will look at the option skipAuth and if true will not use the token
        // currently setting cookies AND header (header to be rethought as it is a potential security issue)
        if (!sd.data.authRequestInterceptor) {
          sd.data.authRequestInterceptor = sd.s.http.interceptRequest((httpConfig: CustomAxiosRequestConfig<any>) => {
            if (this.inMaintenance && !(httpConfig.userConfig || {}).skipMaintenanceCheck) {
              // Throw a valid HttpError instance as this is what any handlers expect to receive
              const error: HttpError<any> = {
                config: httpConfig,
                isAxiosError: false,
                message: 'In maintenance, request rejected',
                name: 'maintenanceError',
                toJSON() {
                  return {};
                },
              };
              return Promise.reject(error);
            }

            if (!(httpConfig.userConfig || {}).skipAuth && this.hasValidSession) {
              // In case a request is made while logged in, we wait on any token refresh actions
              httpConfig.headers = httpConfig.headers || {};
              httpConfig.withCredentials = httpConfig.withCredentials ?? true;
              return this.waitOnrefreshSession().then(() => {
                httpConfig.headers.Authorization = `Bearer ${this.authToken}`;
                return httpConfig;
              });
            } else if (!(httpConfig.userConfig || {}).skipAuth && this.authToken) {
              // In case a request is made while not logged in, but when authToken exists (i.e OTP verification state)
              // We DON'T wait on refresh tokens (as they should not be occurring)
              httpConfig.headers = httpConfig.headers || {};
              httpConfig.headers.Authorization = `Bearer ${this.authToken}`;
            }
            return httpConfig;
          });
        }

        // interceptor to add JWT token to any socket requests
        if (!sd.s.socket.jwtGetter) {
          sd.s.socket.jwtGetter = () => this.authToken;
        }

        // interceptor for GenericErrorCodes.UNAUTHENTICATED responses, will logout and redirect to login page
        if (!sd.data.authResponseInterceptor) {
          sd.data.authResponseInterceptor = sd.s.http.interceptResponse(undefined, (error: any) => {
            if (
              error?.response?.data?.code === SystemErrorCodes.IN_MAINTENANCE &&
              !error?.config?.userConfig?.skipMaintenanceCheck
            ) {
              this.setInMaintenance();
              options.runActions(commonStoreActions.onInMaintenance);
              return Promise.reject(error);
            }
            if (
              this.hasValidSession &&
              error?.response?.data?.code === GenericErrorCodes.UNAUTHENTICATED &&
              !error?.config?.userConfig?.skipAuth
            ) {
              return new Promise<void>((resolve, reject) => {
                if (!error?.config?.userConfig?.is401Retry && this.refreshToken) {
                  resolve();
                } else {
                  reject(error);
                }
              })
                .then(() => {
                  const authTokenUsed = error.config.headers.Authorization.substr(7);
                  const authTokenChanged = authTokenUsed !== this.authToken;
                  if (!authTokenChanged) {
                    // Token is same as current, refreshing session and retrying request
                    return this.refreshSession().then(() => authTokenChanged);
                  }
                  // Token has changes, retrying request without marking it as a retry
                  return authTokenChanged;
                })
                .then((authTokenChanged) =>
                  sd.s.http
                    .request({
                      axiosConfig: {
                        ...error.config,
                        userConfig: {
                          ...error.config.userConfig,
                          is401Retry: !authTokenChanged,
                        },
                      },
                    })
                    .toPromise()
                )
                .catch((e) => {
                  if (e?.response?.data?.code === GenericErrorCodes.UNAUTHENTICATED) {
                    this.logout({
                      message: 'Your session is no longer valid.',
                      messageOptions: { toastType: 'danger', title: 'Error' },
                      redirect: '/login',
                    }).then(() => {
                      throw e;
                    });
                  } else {
                    throw e;
                  }
                });
            }
            return Promise.reject(error);
          });
        }
      },
      /**
       * Start up a new session
       *
       * This will setup all data related to the current session, or a new one, if one is provided.
       * If dealing with a new session, will setup all data BEFORE setting the session,
       * as to only trigger onLogin actions after all necessary data has been loaded
       */
      async startUpSession(payload: { session: SessionData }) {
        const session = cloneDeep(payload.session);

        const skipAuthOptions = {
          options: {
            skipAuth: true,
            errors: { silent: true },
          },
          axiosConfig: {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          },
        };

        checkSessionValidity(session.user!);

        // Handle Users in OTP check stage and registration pre-client creation stage (where no individual is returned)
        if (
          session.isOtpRequired ||
          (!session.user?.individual?.id && session.user?.role === UserRole.CLIENT_REGISTRATION)
        ) {
          return this.setTokenData(session);
        }

        const adminUser = ahRoles.includes(session.user!.role);

        if (!adminUser) {
          // Individual may be under CQRS delay after registratiom
          await waitForEntityCreation(() =>
            sd.s.individual.getIndividual(session.user!.individual!.id, undefined, skipAuthOptions)
          )
            .toPromise()
            .then((individual) => {
              if (session.user) {
                session.user.individual = individual;
              }
            });
        }

        if (!adminUser && !session.user?.individual?.id) {
          // Non admin user with an unset individual (possible Onboarding/Temp status), return early
          return this.setTokenData(session);
        }

        const setupUserInfo = adminUser
          ? sd.s.auth
              .getSession(skipAuthOptions)
              .toPromise()
              .then((sessionUser) => {
                session.user = sessionUser;
              })
          : Promise.all([
              this.loadComplianceCase({ force: true, session }),
              this.loadTermsAndConditions({ force: true, session }),
            ]);

        return setupUserInfo
          .then(async () => {
            await this.setTokenData(session);
            // LoadSessionAuthorities is not blocking, as any service requiring Authorities will load it as well
            this.loadSessionAuthorities({
              force: true,
              auth: session.token,
              userId: session.user?.id,
            });
          })
          .catch((error) => {
            this.logout();
            if (error.response?.status === 404) {
              throw 'sessionNotFound';
            } else {
              throw 'userWithoutIndividual';
            }
          });
      },
      async setInMaintenance() {
        this.inMaintenance = true;
        if (!sd.data.maintenanceCheckSub) {
          const checker = () => {
            sd.data.maintenanceCheckSub = sd.s.branding
              .getPartnerBrandingData(undefined, { options: { skipMaintenanceCheck: true, errors: { silent: true } } })
              .subscribe(
                () => {
                  this.inMaintenance = false;
                  sd.data.maintenanceCheckSub = undefined;
                  options.runActions(commonStoreActions.onOutMaintenance).then(() => {
                    const updaterState = options.getAppUpdater();
                    updaterState.checkAppVersion().then(() => {
                      if (!updaterState.isUpToDate) {
                        updaterState.reloadToUpdate();
                      }
                    });
                  });
                },
                () => {
                  window.setTimeout(checker, 10000);
                }
              );
          };

          checker();
        }
      },
      async recheckMaintenanceCheck() {
        if (!this.inMaintenance && sd.data.maintenanceCheckSub) {
          sd.data.maintenanceCheckSub.unsubscribe();
          sd.data.maintenanceCheckSub = undefined;
        }
      },
      loadTermsAndConditions(payload: { force: boolean; session?: SessionData } = { force: false }) {
        const options: HttpRequestOptions = {
          axiosConfig: {},
          options: {
            errors: {
              silent: (error) => error.response?.status === 404,
            },
          },
        };

        if (payload?.session) {
          options.axiosConfig.headers = {
            Authorization: `Bearer ${payload.session.token}`,
          };

          options.options = {
            ...options.options,
            skipAuth: true,
          };
        }

        const isClientUser = payload.session
          ? userHasRole(payload.session.user || { role: '' as any }, clientRoles)
          : this.isClientUser;

        if (!isClientUser) {
          this.setTermsAndConditions();
          return Promise.resolve(true);
        }

        const id = payload.session?.user?.individual?.client?.id || this.loggedInIdentity!.client!.id;
        if (id) {
          if (!this.userData?.individual?.owner) {
            this.setTermsAndConditions();
            return Promise.resolve(true);
          }

          if (!payload.force && this.termsAndConditionsAccepted !== null) {
            return Promise.resolve(this.termsAccepted);
          }

          return sd.reqManager
            .sameOrCancelAndNew(
              'loadClientData',
              // Client may be under CQRS delay after registratiom
              waitForEntityCreation(() => sd.s.client.getClient(id, false, undefined, options))
            )
            .pipe(tap((client) => this.setTermsAndConditions(!!client.termsAndConditionsDate)))
            .pipe(mergeMap((client) => of(!!client.termsAndConditionsDate)))
            .toPromise();
        }

        return Promise.reject('No user data found!');
      },
      impersonate(payload: { token?: string }) {
        const router = options.getRouter();

        if (!payload.token) {
          options.getToast().error('Impersonation failed.');
          router.replace('/');
          return Promise.reject();
        }

        router.onReady(() => {
          router.replace('/impersonate');
        });

        return sd.s.auth
          .refreshSession(payload.token)
          .toPromise()
          .then((session) => this.logout({ redirect: false }).then(() => this.startUpSession({ session })))
          .then(() => {
            this.impersonated = true;
            this.triggerSessionStartActions();
            return this.userData;
          })
          .then(
            () => {
              options.getToast().success(`Successufully impersonated user ${this.loggedInUser?.email}`);
              router.replace('/dashboard');
            },
            () => {
              options.getToast().error('Impersonation failed.');
              router.replace('/');
            }
          );
      },
      async setActivityMonitor() {
        if (!sd.data.activityMonitorSet) {
          sd.data.activityMonitorSet = true;

          const activityStore = useActivityStore();

          if (sd.data.refreshTokenTimeout) {
            clearTimeout(sd.data.refreshTokenTimeout);
          }

          const refreshToken = () => {
            if (this.hasValidSession) {
              if (activityStore.lastActivityTime <= Date.now() - REFRESH_TIMEOUT_DURATION * 2) {
                this.testSessionExpiry();
              } else if (this.refreshToken) {
                this.refreshSession().catch(() =>
                  this.logout({
                    message: 'Your session is no longer valid.',
                    messageOptions: { toastType: 'danger', title: 'Error' },
                    redirect: '/login',
                  })
                );
              }
            }
            sd.data.refreshTokenTimeout = window.setTimeout(refreshToken, REFRESH_TIMEOUT_DURATION);
          };

          sd.data.refreshTokenTimeout = window.setTimeout(refreshToken, REFRESH_TIMEOUT_DURATION);
        }
      },
      async testSessionExpiry() {
        if (this.hasValidSession) {
          if (!this.sessionExpiry || this.sessionExpiry < Date.now()) {
            return this.logout({
              message: "You've been logged out due to inactivity.",
              messageOptions: { toastType: 'danger', title: 'Error' },
              redirect: '/login',
            });
          }
        }
      },
      async setTokenData(sessionData: SessionData) {
        // TODO remove usage of JWT when isOtpRequired is added to the payload
        const tokenData = parseJwt(sessionData.token);
        const tokenUser = tokenData.su as FullSessionUser;
        const sessionUser: SessionUser = {
          ...tokenUser,
          ...sessionData.user,
        };

        if (!options.admin) {
          sessionUser.individual = {
            ...tokenUser.individual,
            ...(sessionData.user?.individual as any),
          };
        }

        checkSessionValidity(sessionUser);

        this.authToken = sessionData.token || null;
        this.sessionExpiry = Date.now() + (sessionData.expiresIn ?? tokenData.exp - tokenData.iat) * 1000;
        this.refreshToken = sessionData.refreshToken || null;
        this.lastLoginTimestamp = sessionData.user?.previousLogin ?? null;
        this.setTokenUser(sessionUser);
      },
      setTokenUser(sessionUser: SessionUser) {
        sessionUser.isOtpRequired = !!sessionUser.isOtpRequired;
        this.userData = {
          ...sessionUser,
          individual: {
            ...(this.userData?.individual as any),
            ...sessionUser.individual,
          },
        };
        if (this.userData.authorities?.length) {
          this.sessionAuthorities;
          CachedItem.setCachedItem(this.sessionAuthorities, this.userData.authorities);
        }
      },
      clearTokenUser() {
        this.userData = null;
        this.termsAndConditionsAccepted = null;
        this.authToken = null;
        this.refreshToken = null;
        this.lastLoginTimestamp = null;
        this.cachedComplianceCase = makeCachedComplianceCase();
        this.sessionAuthorities = new CachedItem();
      },
      setIdentity(individual: Individual) {
        if (!this.userData) {
          throw new Error('No userData stored!');
        }
        this.userData.individual = individual;
      },
      setTermsAndConditions(accepted = true) {
        this.termsAndConditionsAccepted = accepted;
      },
      async [commonStoreActions.onSetup]() {
        sd.tabSync.onActionTrigger(REFRESH_SYNC_ACTION, (message: SyncMessage<{ data: { token: string } }>) => {
          if (message.payload.data.token === this.authToken) return this._refreshSession();
        });

        if (!this.hasValidSession) {
          this.clearTokenUser();
          this.sessionExpiry = -1;
          this.impersonated = false;
        }

        await this.setInterceptors();
        await this.setActivityMonitor();
        await this.testSessionExpiry();

        if (this.inMaintenance) {
          this.setInMaintenance();
        } else {
          await this.setupSession();
        }
      },
      triggerSessionStartActions() {
        if (!this.hasValidSession) {
          return;
        }
        options.runActions(commonStoreActions.onSessionStart);
        if (!this.hasRegistrationSession) {
          options.runActions(commonStoreActions.onLogin);
        }
      },
      async [commonStoreActions.onOutMaintenance]() {
        options.getOtp().clear();
        options.getToast().clear();
      },
      async [commonStoreActions.onLogout]() {
        options.getOtp().clear();
        options.getToast().clear();
      },
      async [commonStoreActions.onLogoutOtherTab]() {
        options.getOtp().clear();
        options.getToast().clear();
      },
      async setupSession() {
        const impersonateTokens = location.pathname === '/impersonate' ? getImpersonateTokens() : null;

        if (impersonateTokens) {
          await this.impersonate(impersonateTokens);
        } else if (this.hasValidSession && (await sd.tabSync.isLeaderPromise)) {
          const loadPromises: Promise<any>[] = [];
          if (!this.isUserVerified) {
            loadPromises.push(this.loadComplianceCase(), this.loadTermsAndConditions());
          }
          loadPromises.push(this.loadSessionAuthorities());

          await Promise.all(loadPromises);
        }
      },
      login(payload: { email: string; password: string; silenceErrors?: boolean }) {
        return sd.reqManager
          .sameOrCancelAndNew(
            'login',
            sd.s.auth.login(
              { email: payload.email, password: payload.password },
              { errors: { silent: payload.silenceErrors } }
            ),
            payload
          )
          .toPromise()
          .then((response) => this.startUpSession({ session: response }))
          .then(() => {
            this.triggerSessionStartActions();
            return this.userData;
          });
      },
      loginWithAD(payload: { code: string; silenceErrors?: boolean }) {
        return sd.reqManager
          .sameOrCancelAndNew(
            'login',
            sd.s.auth.loginWithAD(payload.code, { errors: { silent: payload.silenceErrors } }),
            payload
          )
          .toPromise()
          .then((response) => this.startUpSession({ session: response }))
          .then(() => {
            this.triggerSessionStartActions();
            return this.userData;
          });
      },
      async waitOnrefreshSession() {
        return sd.reqManager.waitForRequests(['refresh']);
      },
      async refreshSession() {
        if (await sd.tabSync.isLeaderPromise) {
          return this._refreshSession();
        } else {
          return sd.reqManager.newPromise(
            'refresh',
            sd.tabSync.awaitLeaderAction(REFRESH_SYNC_ACTION, { token: this.authToken })
          );
        }
      },
      _refreshSession() {
        let refreshToken = this.refreshToken;
        return sd.reqManager
          .sameOrCancelAndNew(
            'refresh',
            // FIXME this checks for response consistency (i.e. the Client in the response matches the Client in the token)
            // We should remove once the API guarantees this
            waitForEntityChange(
              () => sd.s.auth.refreshSession(refreshToken),
              (session) => {
                const jwtSession = JSON.parse(atob(session.token.split('.')[1]));
                if (session.user?.individual?.id && !jwtSession.su?.individual?.id) {
                  refreshToken = session.refreshToken;
                  return false;
                }
                return true;
              }
            ).pipe(mergeMap((response) => from(this.startUpSession({ session: response })))),
            this.refreshToken
          )
          .toPromise()
          .catch(() => this.logout());
      },
      otp(payload: string) {
        return sd.reqManager
          .sameOrCancelAndNew('otp', sd.s.auth.otp(payload), payload)
          .toPromise()
          .then((response) => this.startUpSession({ session: response }))
          .then(() => {
            this.triggerSessionStartActions();
            return this.userData;
          });
      },
      registrationMfa() {
        return sd.reqManager
          .sameOrCancelAndNew('mfa', sd.s.auth.redeemRegistrationMFA())
          .toPromise()
          .then((response) => this.startUpSession({ session: response }))
          .then(() => this.userData);
      },
      async logout(opts?: { redirect?: string | false; message?: string; messageOptions?: any }) {
        if (this.hasValidSession || this.hasOTPSession) {
          sd.reqManager.clear(['logout']);
          return sd.reqManager.currentOrNewPromise(
            'logout',
            () =>
              new Promise<void>((resolve, reject) => {
                sd.s.auth.logout({ errors: { silent: true } }).subscribe();
                window.setTimeout(() => {
                  try {
                    this.clearTokenUser();
                    this.impersonated = false;
                    const toastOpts = {
                      message: '',
                      messageOptions: {
                        type: 'default',
                      },
                      ...opts,
                    };
                    const redirect = opts?.redirect !== false ? opts?.redirect || '/' : false;
                    options.runActions(commonStoreActions.onLogout);
                    if (toastOpts.message) {
                      options.getToast().show(toastOpts.message, toastOpts.messageOptions);
                    }
                    if (redirect) {
                      window.setTimeout(() => {
                        if (redirect === options.getRouter().currentRoute.path) {
                          options.getRouter().go(0);
                        } else {
                          options.getRouter().push(redirect);
                        }
                      });
                    }
                    resolve();
                  } catch (e) {
                    reject(e);
                  }
                });
              })
          );
        }
      },
    },
  });
}

export const useActivityStore = defineStore(
  'activityStore',
  () => {
    const lastActivityTime = ref(Date.now());

    const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    activityEvents.forEach((eventName) => {
      document.addEventListener(
        eventName,
        () => {
          lastActivityTime.value = Date.now();
        },
        true
      );
    });

    return { lastActivityTime };
  },
  {
    persist: true,
  }
);
