<script lang="ts" setup>
import { TradeDestination } from './forms/tradeDestination';
import { TradeFundsDestination } from '../models/trade';
import { Observable } from 'rxjs';
import UpdatedDate from 'ah-common-lib/src/common/components/time/UpdatedDate.vue';
import TradePriceMarginInfoView from './info/TradePriceMarginInfoView.vue';
import TradeBeneficiaryInfo from './info/TradeBeneficiaryInfo.vue';
import CostTransparencyDetailsInfo from './info/CostTransparencyDetailsInfo.vue';
import TradeDetailsInfo from './info/TradeDetailsInfo.vue';
import { pricingRequest, QuotePriceRequest } from '../requests/pricingRequest';
import { getChildModel, makeFormModel, setState } from 'ah-common-lib/src/form/helpers';
import { checkboxField } from 'ah-common-lib/src/form/models';
import {
  isPriceExpired,
  HedgingInstruments,
  getBuyCcy,
  AuthorityType,
  TradeFundingDetails,
  AmountType,
  FeatureFlag,
  FeatureFlagTreatment,
} from 'ah-api-gateways';
import WalletTransferText from 'ah-wallets/src/components/WalletTransferText.vue';
import { reactive, onBeforeUnmount, computed, watch, ref } from 'vue';
import { useAhTradesState } from '..';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { isEmpty } from 'lodash';
import { useAggregatedPaymentLimitChecker } from '../composables/aggregatedPaymentLimitChecker';
import AggregatedLimitErrorMessage from '../../../ah-payments/src/components/AggregatedLimitErrorMessage.vue';

const props = withDefaults(
  defineProps<{
    /**
     * Trade destination object (managed by TradeDestinationForm)
     */
    tradeDestination: TradeDestination;
    /**
     * Trade price request and response (as received by getting/refreshing  the trade)
     *
     * synchronizable via `update:tradePrice` or .sync modifier
     */
    tradePrice: QuotePriceRequest;
    /**
     * Trade Funding Details (managed by TradeFundForm)
     */
    tradeFundingDetails?: Partial<TradeFundingDetails>;
    /**
     * Whether the trade creation is in a loading state
     *
     * Used to display a loading icon on the submit button and prevent refresh warnings
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    loading?: boolean | string;
    /**
     * Whether the UI is narrow (i.e. displayed in a single column)
     *
     * Used when in a modal context
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    narrow?: boolean | string;
    /**
     * Whether to sync trade funds.
     *
     * If set, trades will be synchronized - for confirmation purposes this means margins in the pricing will be ignored
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    syncTradeFunds?: boolean | string;
    /**
     * Whether to show cost transparency
     *
     * If true:
     * - client will show cost transparency
     */
    costTransparency?: string | boolean;
    costTransparencyCurrency: AmountType;
  }>(),
  {
    loading: false,
    narrow: false,
    syncTradeFunds: false,
    costTransparency: false,
  }
);

const emit = defineEmits<{
  (e: 'outdated-price'): void;
  (e: 'update:tradePrice', value: QuotePriceRequest): void;
  (e: 'trade-confirmed'): void;
  (e: 'revert-to-form'): void;
  (e: 'update:costTransparencyCurrency', value: AmountType): void;
}>();

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const requestManager = useRequestManager();

const displayCostTransparency = ref<boolean>(false);

const showCostTransparency = computed(() => props.costTransparency !== false);

function loadFeatureFlag() {
  const payload = {
    featureFlag: FeatureFlag.PRICING_SHOW_CLIENT_COSTS,
    oboClientId: onBehalfOfClient.value?.id,
    force: true,
  };

  tradeState.store
    .useFeatureFlagStore()
    .loadFeatureFlag(payload)
    .then(
      (featureFlagResponse) =>
        (displayCostTransparency.value = featureFlagResponse?.treatment === FeatureFlagTreatment.ON)
    );
}

// We skip feature flags checking in x-office to hide cost transparency in trading obo
if (!tradeState.store.useAuthStore().isAHUser && showCostTransparency.value) {
  loadFeatureFlag();
}

const state = reactive<{
  areIMLimitOrCollateralProfileInvalid: boolean;
  needsPriceRefresh: boolean;
  sufficientCollateral: boolean;
  recheckRefreshTimeout: number | null;
}>({
  areIMLimitOrCollateralProfileInvalid: false,
  needsPriceRefresh: false,
  sufficientCollateral: false,
  recheckRefreshTimeout: null,
});

const clientId = computed(() => {
  return onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id ?? '';
});

const oboClientId = computed(() => onBehalfOfClient.value?.id);

const { aggregatedLimitForCurrency, aggregatedPaymentReviewRoute, isAggregatedLimitReached, loadAggregatedLimit } =
  useAggregatedPaymentLimitChecker({
    requestManager: requestManager.manager,
    services: tradeState.services,
    data: {
      clientId,
      oboClientId,
      paymentCurrency: computed(() => props.tradePrice.tradeDetails.buyCurrency),
      paymentAmount: computed(() => props.tradePrice.response?.ccy1.clientAmount),
    },
  });

const forwardConfirmForm = reactive<FormDefinition>({
  form: makeFormModel({
    fieldType: 'form',
    name: 'forwardConfirmForm',
    fields: [
      checkboxField(
        'confirmed',
        'I confirm that the purpose of this trade is to facilitate a payment for goods and services or direct investment.',
        false,
        {
          required: false,
          errorMessages: {
            mustAccept: 'Must confirm this to continue',
          },
        }
      ),
    ],
  }),
  validation: null,
});

onBeforeUnmount(() => {
  if (state.recheckRefreshTimeout) {
    clearTimeout(state.recheckRefreshTimeout);
  }
});

const canSeeCollateral = computed(() => tradeState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_COLLATERALS));

const isNarrow = computed(() => props.narrow !== false);

const isLoading = computed(() => props.loading !== false);

const isSyncTradeFunds = computed(() => props.syncTradeFunds !== false);

const validFunding = computed(() => {
  if (!props.tradeFundingDetails || isEmpty(props.tradeFundingDetails)) return true;
  return props.tradeFundingDetails.valid;
});

const isAForward = computed(() => {
  return [
    HedgingInstruments.FX_FORWARD,
    HedgingInstruments.WINDOW_FORWARD,
    HedgingInstruments.FLEXIBLE_FORWARD,
  ].includes(props.tradePrice.response?.hedgingProduct!);
});

const isUnconfirmedForward = computed(() => isAForward.value && !forwardConfirmForm.form.confirmed);

const buyCcy = computed(() => {
  if (props.tradePrice && props.tradePrice.response) {
    return getBuyCcy(props.tradePrice.response);
  }
  return undefined;
});

function recheckRefresh() {
  state.needsPriceRefresh = !!(
    props.tradePrice.response && isPriceExpired(props.tradePrice.response, props.tradePrice.responseTimeOffset)
  );
  state.recheckRefreshTimeout = window.setTimeout(() => recheckRefresh(), 1000);
}

recheckRefresh();

function reloadPrices() {
  if (props.loading) {
    return;
  }
  const cutoffDate = new Date(props.tradePrice.tradeDetails.timeFrame?.cutoffDateTime ?? NaN);
  if (cutoffDate < new Date()) {
    emit('outdated-price');
    return;
  }
  loadAggregatedLimit();
  return requestManager.manager.sameOrCancelAndNew('reloadPrices', pricingRequestObs()).subscribe((price) => {
    emit('update:tradePrice', price);
  });
}

function pricingRequestObs(): Observable<QuotePriceRequest> {
  return pricingRequest(props.tradePrice.tradeDetails, clientId.value, tradeState.services.pricingEngine);
}

function confirmTrade() {
  emit('trade-confirmed');
}

function revertToForm() {
  emit('revert-to-form');
}

watch(
  validFunding,
  () => {
    setState(getChildModel(forwardConfirmForm.form, 'confirmed')!, 'readonly', !validFunding.value);
    if (!validFunding.value) {
      forwardConfirmForm.form.confirmed = false;
      forwardConfirmForm.validation?.$reset();
    }
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <!-- main blocks -->
    <BoxGrid alignH="start" alignV="stretch">
      <!-- keep or send funds -->
      <BoxGridItem v-bind="!isNarrow ? { cols: 12, md: 12, lg: 6 } : { cols: 12 }" align-self="stretch">
        <BoxGrid alignH="start" alignV="stretch">
          <BoxGridBlock class="mb-3" cols="12" align-self="stretch">
            <div :class="['trade-details', { 'needs-price-refresh': !isLoading && state.needsPriceRefresh }]">
              <h3>Trade Details</h3>
              <UpdatedDate
                v-if="
                  (tradePrice && tradePrice.response) ||
                  (tradePrice && tradePrice.response && requestManager.manager.requestStates.reloadPrices === 'pending')
                "
                :date="tradePrice && tradePrice.response ? tradePrice.response.priceRequestedTimestamp : ''"
                :offsetMillis="tradePrice && tradePrice.responseTimeOffset"
                dateStyle="short"
                :loading="requestManager.manager.requestStates.reloadPrices === 'pending'"
                @refresh="reloadPrices"
                class="trade-updated-date"
              />
              <TradeDetailsInfo
                v-if="tradePrice && tradePrice.response"
                :tradePriceResponse="tradePrice.response"
                class="trade-detail-items"
              />
            </div>
            <ExpandTransition appear>
              <div class="trade-refresh" v-if="!isLoading && state.needsPriceRefresh">
                <p>This rate is no longer up to date. Please refresh it in order to continue.</p>
                <VButton
                  @click="reloadPrices"
                  :loading="requestManager.manager.requestStates.reloadPrices === 'pending'"
                  class="btn-success"
                >
                  <IconRefresh />
                  Refresh rate
                </VButton>
              </div>
            </ExpandTransition>
          </BoxGridBlock>
          <BoxGridBlock class="mb-3" cols="12" align-self="stretch">
            <template v-if="tradeDestination.destination === TradeFundsDestination.KEEP">
              <h3>Keep Funds</h3>
              <WalletTransferText
                v-if="buyCcy"
                :destinationAmount="buyCcy.clientAmount"
                :destinationCurrency="buyCcy.currency"
              />
            </template>
            <template v-else>
              <h3>Send money to</h3>
              <AggregatedLimitErrorMessage
                v-if="isAggregatedLimitReached && aggregatedLimitForCurrency"
                :aggregatedLimitForCurrency="aggregatedLimitForCurrency"
              >
                <template #route>
                  <span v-if="oboClientId">please go to the payments page</span>
                  <RouterLink v-else :to="aggregatedPaymentReviewRoute">please click here</RouterLink>
                </template>
              </AggregatedLimitErrorMessage>
              <TradeBeneficiaryInfo
                sm="2"
                v-if="tradeDestination && tradeDestination.beneficiary"
                :tradeDestination="tradeDestination"
              />
            </template>
          </BoxGridBlock>
          <BoxGridBlock class="mb-3" cols="12" align-self="stretch" v-if="displayCostTransparency">
            <CostTransparencyDetailsInfo
              :tradeDetails="tradePrice.tradeDetails"
              :tradePrice="tradePrice?.response"
              :costTransparencyCurrency="costTransparencyCurrency"
              @update:costTransparencyCurrency="emit('update:costTransparencyCurrency', $event)"
            />
          </BoxGridBlock>
        </BoxGrid>
      </BoxGridItem>
      <BoxGridItem v-bind="!isNarrow ? { cols: 12, md: 12, lg: 6 } : { cols: 12 }" align-self="stretch">
        <BoxGrid alignH="start" alignV="stretch">
          <TradePriceMarginInfoView
            v-if="tradePrice && tradePrice.response && canSeeCollateral && !isSyncTradeFunds"
            :tradePriceResponse="tradePrice.response"
            :tradeFundingDetails="tradeFundingDetails"
            :sufficientCollateral.sync="state.sufficientCollateral"
            :areIMLimitOrCollateralProfileInvalid.sync="state.areIMLimitOrCollateralProfileInvalid"
            v-on="$listeners"
            :boxGridBlockProps="{ class: 'mb-3', cols: 12, alignSelf: 'stretch' }"
          />
          <!-- keep or send funds -->
          <BoxGridItem
            class="mb-3"
            v-if="isAForward && !state.areIMLimitOrCollateralProfileInvalid"
            cols="12"
            align-self="stretch"
          >
            <div :class="['forward-confirm-box-wrapper', { 'is-narrow': isNarrow }]">
              <ValidatedForm :fm="forwardConfirmForm.form" :validation="forwardConfirmForm.validation" />
            </div>
          </BoxGridItem>
        </BoxGrid>
      </BoxGridItem>
    </BoxGrid>
    <div class="d-flex w-100 justify-content-between mt-4 align-items-end">
      <VButton @click="revertToForm" class="text-nowrap btn-secondary mr-2">
        <IconArrowRight class="mr-3 back-arrow" /> Back
      </VButton>
      <VButton
        class="btn-success"
        :loading="loading"
        :disabled="
          state.needsPriceRefresh ||
          (!isSyncTradeFunds && !state.sufficientCollateral) ||
          (isAForward && state.areIMLimitOrCollateralProfileInvalid) ||
          isUnconfirmedForward ||
          !validFunding
        "
        @click="confirmTrade"
      >
        Confirm
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aggregated-limit-error-message {
  color: $red;
}
.trade-updated-date {
  position: absolute;
  top: 24px;
  right: 20px;
  font-size: 14px;
  @include themedTextColor($color-text-secondary);
}

.forward-confirm-box-wrapper {
  &.is-narrow {
    max-width: 100%;
    width: 100%;
    word-break: break-word;
  }
}

.needs-price-refresh > * {
  opacity: 0.4;
}

.trade-refresh {
  text-align: center;
}

.back-arrow {
  transform: rotate(180deg);
}
</style>
