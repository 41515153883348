<script setup lang="ts">
import AddOrConvertCurrencyButtons from './AddOrConvertCurrencyButtons.vue';
import CollateralCurrencyTransactionForm from './CollateralCurrencyTransactionForm.vue';
import FormattedCurrency from '../FormattedCurrency.vue';
import { BModal } from 'bootstrap-vue';
import { CollateralTransactionState, Wallet } from 'ah-api-gateways';
import { Observable, from, combineLatest } from 'rxjs';
import { VButton, VCol, VRow } from 'ah-common-lib/src/common/components';
import { capitalize } from 'lodash';
import { computed, PropType, ref, watch } from 'vue';
import { resetForm } from 'ah-common-lib/src/form/helpers';
import { mergeMap } from 'rxjs/operators';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { useAhTradesState } from '../../..';
import { waitForEntityChange, HttpError } from 'ah-requests';
import { ManageCollateralActionType, ManageCollateralCurrenciesState } from '../interfaces';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';

const props = defineProps({
  activeMarginCall: {
    type: Boolean,
    required: true,
  },
  availableCollateral: {
    type: Number,
    required: true,
  },
  currenciesState: {
    type: Object as PropType<ManageCollateralCurrenciesState>,
    required: true,
  },
  disablePostCollateral: {
    type: Boolean,
    required: true,
  },
  isPartnerUser: {
    type: Boolean,
    required: true,
  },
  ownerId: {
    type: String,
    required: true,
  },
  isCreditProfileUtilisationExceeded: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits<{
  /*
   * reload-collateral - emitted when the transaction has been executed successfully to reload again the collateral values
   */
  (e: 'reload-collateral'): void;
  /*
   * action-changed - inform the parent of which radio button is selected
   */
  (e: 'action-changed', value: ManageCollateralActionType): void;
}>();

const requestManager = useRequestManager();

const toast = useToast();

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const completeActionModal = ref<InstanceType<typeof BModal> | null>(null);

const selectedActionType = ref<ManageCollateralActionType>('withdraw');

const totalEquivalent = computed(() => {
  return props.currenciesState.currencies.reduce((total, { currency, model }) => {
    const rate = props.currenciesState.rates?.[currency.toLowerCase()] ?? 0;
    const amount = model?.amount ?? 0;
    return total + amount * rate;
  }, 0);
});

const isOverWithdrawing = computed(
  () => selectedActionType.value === 'withdraw' && totalEquivalent.value > props.availableCollateral
);

const disableWithdrawCollateral = computed(
  () => props.activeMarginCall || props.isCreditProfileUtilisationExceeded || props.availableCollateral <= 0
);

const isFormInvalid = computed(() => {
  if (totalEquivalent.value === 0 || isOverWithdrawing.value) {
    return true;
  }

  if (selectedActionType.value === 'withdraw' && props.activeMarginCall) {
    return true;
  }

  return props.currenciesState.currencies.some((currencyObj) => {
    const { currency, model, validation } = currencyObj;

    if (validation?.$invalid) {
      return true;
    }

    if (
      model &&
      model.amount > 0 &&
      !tradeState.store.useWalletsStore().getWallet(currency, { isPartner: props.isPartnerUser, id: props.ownerId })
    ) {
      return true;
    }

    return false;
  });
});

const toastMessagePrefix = computed(
  () => `${selectedActionType.value === 'post' ? 'Posted to' : 'Withdrew from'} collaterals`
);

const TX_ERROR = Symbol('Collateral Transaction has failed');

function performTransaction() {
  const requests: Observable<Wallet | null>[] = [];

  props.currenciesState.currencies.forEach((c) => {
    if (c.model && c.model.amount > 0) {
      const wallet = tradeState.store.useWalletsStore().getWallet(c.currency, {
        isPartner: props.isPartnerUser,
        id: props.ownerId,
      });

      const payload: { amount: number; currency: string } = { amount: c.model.amount, currency: c.currency };

      if (wallet && props.ownerId) {
        const request =
          selectedActionType.value === 'withdraw'
            ? tradeState.services.trade.withdrawCollateral(props.ownerId, payload, onBehalfOfClient.value?.id)
            : tradeState.services.trade.postCollateral(props.ownerId, payload, onBehalfOfClient.value?.id);

        requests.push(
          request.pipe(
            mergeMap((collateralTxVersionedObject) =>
              waitForEntityChange(
                () => tradeState.services.trade.getCollateralTransaction(collateralTxVersionedObject.id),
                (collateral) => {
                  if (collateral.state === CollateralTransactionState.FAILED) {
                    throw TX_ERROR;
                  }

                  return collateral.state === CollateralTransactionState.COMPLETED;
                },
                {
                  ignoreErrors(e: HttpError) {
                    return e.response?.status === 404;
                  },
                }
              )
            ),
            mergeMap(() =>
              from(
                tradeState.store.useWalletsStore().waitForWalletChange({
                  id: wallet.id,
                })
              )
            )
          )
        );
      }
    }
  });

  return requestManager.manager.new('performTransaction', combineLatest(requests)).subscribe({
    complete: () => {
      toast.success(`${toastMessagePrefix.value} successfully.`);

      emit('reload-collateral');

      props.currenciesState.currencies.forEach((currencyObj) => {
        currencyObj.model = { amount: 0 };
        if (currencyObj.validation) {
          resetForm(currencyObj.validation);
        }
      });
    },
    error: (err: any) => {
      if (err === TX_ERROR) {
        toast.error(`${toastMessagePrefix.value} failed.`);
      } else {
        toast.error('An unexpected problem has occurred. Please try again later.');
      }
    },
  });
}

function openModal() {
  completeActionModal.value?.show();
}

function onClick() {
  if (onBehalfOfClient.value) {
    openModal();
  } else {
    performTransaction();
  }
}

function onActionTypeClick(event: any) {
  selectedActionType.value = event.target.value;
}

watch(selectedActionType, () => emit('action-changed', selectedActionType.value), { immediate: true });

watch(
  () => disableWithdrawCollateral.value,
  () => {
    if (disableWithdrawCollateral.value && !props.disablePostCollateral) {
      selectedActionType.value = 'post';
    }
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <VRow>
      <VCol lg="7" sm="12">
        <p>
          You can post into or withdraw collateral from your <strong>EUR</strong>, <strong>GBP</strong> or
          <strong>USD</strong> wallets
        </p>
        <div class="custom-control custom-radio mb-5">
          <input
            :checked="selectedActionType === 'withdraw'"
            @click="onActionTypeClick"
            class="custom-control-input"
            :disabled="disableWithdrawCollateral"
            id="action-type-withdraw"
            type="radio"
            value="withdraw"
          />
          <label for="action-type-withdraw" class="custom-control-label">Withdraw Collateral</label>
          <div v-show="disableWithdrawCollateral" class="text-secondary text-small mt-1">
            You do not have sufficient available collateral to withdraw
          </div>
        </div>
        <div class="custom-control custom-radio">
          <input
            :checked="selectedActionType === 'post'"
            @click="onActionTypeClick"
            class="custom-control-input"
            id="action-type-post"
            type="radio"
            value="post"
          />
          <label for="action-type-post" class="custom-control-label">Post Collateral</label>
          <div class="text-secondary text-small mt-1">
            Post Collateral from the Available Balance in your EUR, GBP or USD Wallets.
          </div>
        </div>
      </VCol>
      <VCol lg="5" sm="12">
        <div class="mb-3 text-right bold">Amount to {{ capitalize(selectedActionType) }}</div>
        <CollateralCurrencyTransactionForm
          v-for="currencyObj in currenciesState.currencies"
          class="mb-4"
          :currency="currencyObj.currency"
          :disablePostCollateral="disablePostCollateral"
          :key="currencyObj.currency"
          :model.sync="currencyObj.model"
          :ownerId="ownerId"
          :type="selectedActionType"
          :validation.sync="currencyObj.validation"
        />
        <VRow alignV="center" class="text-right">
          <VCol cols="7" class="bold">GBP Equivalent Total</VCol>
          <VCol cols="5" class="themed pr-3">
            <FormattedCurrency :value="totalEquivalent" />
          </VCol>
        </VRow>
        <VRow class="mt-3" v-if="isOverWithdrawing">
          <VCol class="text-right error-message" cols="12">
            You cannot exceed the total limit of GBP {{ formatCurrencyValue(availableCollateral) }}
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow class="mt-4">
      <VCol class="flex-item" lg="9" sm="12">
        <AddOrConvertCurrencyButtons v-if="!disablePostCollateral" />
      </VCol>
      <VCol lg="3" sm="12" class="flex-item text-right">
        <VButton
          class="action new-xhedge-styles"
          :label="`${capitalize(selectedActionType)} Collateral`"
          :disabled="isFormInvalid"
          :loading="requestManager.manager.anyPending"
          roundedBorder="true"
          @click="onClick"
        />
      </VCol>
    </VRow>
    <BModal
      v-if="onBehalfOfClient"
      ref="completeActionModal"
      :centered="true"
      footer-class="justify-content-center"
      ok-title="Confirm"
      ok-variant="success"
      @ok="performTransaction"
    >
      <div class="text-center">
        <p>You are about to {{ selectedActionType === 'withdraw' ? 'withdraw' : 'post' }} collateral from the</p>
        <p>{{ onBehalfOfClient.name }} wallet on their behalf</p>
      </div>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
.themed,
.bold {
  font-weight: $font-weight-bold;
}
.themed {
  @include themedTextColor($color-primary, $color-dark-primary);
}

.error-message {
  color: getColor($color-danger);
}

@include upToResolution($desktopResolution) {
  .flex-item:nth-child(1) {
    order: 1;

    ::v-deep {
      span {
        width: 50%;
        &:first-child {
          margin-right: 1rem;
        }
        button {
          width: 100%;
        }
      }
    }
  }

  /* Last item as first */
  .flex-item:nth-child(2) {
    text-align: center !important;
    order: -1;
    margin-bottom: 1rem;
    button {
      width: 100%;
    }
  }
}
</style>
