<script lang="ts" setup>
import { getChildModel, makeFormModel, setState, batchSetState } from 'ah-common-lib/src/form/helpers';
import { ApiError } from 'ah-requests';
import { numberField, textField } from 'ah-common-lib/src/form/models/commonFields';
import { Beneficiary, FxMargins, getCurrencyPair, getPrimaryCcy, getSecondaryCcy } from 'ah-api-gateways';
import { TradeDetails } from '../../models/trade';
import TradePriceExchangeRate from '../info/TradePriceExchangeRate.vue';
import { QuotePriceRequest } from '../../requests/pricingRequest';
import { FormEvent, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import PartnerProfitInfo from '../info/PartnerProfitInfo.vue';
import { computed, reactive, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '../..';

import { Validation } from '@vuelidate/core';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const emit = defineEmits<{
  /** Emits:
   * set-markup (payload: Partial<TradeDetails>) includes either markup or rate information, as provided by the user
   */
  (e: 'set-markup', payload: Partial<TradeDetails>): void;
  (e: 'update:validations', payload: Validation | null): void;
}>();

const props = withDefaults(
  defineProps<{
    /**
     * Trade details object
     */
    tradeDetails?: TradeDetails | null;
    /**
     * Possible beneficiary target of the trade
     * Will affect UX and options available in child components
     */
    tradePrice?: QuotePriceRequest | null;
    /**
     * Possible beneficiary target of the trade
     * Will affect UX and options available in child components
     */
    beneficiary?: Beneficiary;
    /**
     * Possible pricing engine error, FOR DISPLAY PURPOSES
     */
    peError?: ApiError | null;
  }>(),
  {
    tradeDetails: null,
    tradePrice: null,
  }
);

const state = reactive<{
  // Possible Pricing engine error to show (if relevant)
  peErrorText: string;
  calculating: boolean;
  clientSettings: FxMargins | null;
}>({
  peErrorText: '',
  calculating: false,
  clientSettings: null,
});

const clientRateAndMarginFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'clientRateAndMarginForm',
    fieldType: 'form',
    fields: [
      textField('clientRate', 'Client Rate', {
        fieldWrapperClass: 'col col-6 px-md-4 px-sm-2 text-center',
        required: false,
        defaultValue: undefined,
      }),
      numberField('clientRateMarkup', 'Markup', {
        fieldWrapperClass: 'col col-5 mb-2 px-md-4 px-sm-3 text-center',
        required: false,
        defaultValue: undefined,
        min: 0,
      }),
    ],
  }),
  validation: null,
});

const requestManager = useRequestManager({
  exposeToParent: ['getClientSettings'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'getClientSettings') {
      onClientChange();
    }
  },
});

const tradeState = useAhTradesState();

const isFormInvalid = computed(() => {
  if (clientRateAndMarginFormDef.validation) {
    return clientRateAndMarginFormDef.validation.$error;
  }
  return true;
});

const tradeCurrencyPair = computed(() => {
  if (props.tradePrice?.response) {
    return getCurrencyPair(props.tradePrice.response);
  }
  return '';
});

const clientRate = computed(() => {
  if (props.tradePrice?.response) {
    return getPrimaryCcy(props.tradePrice.response).clientRate.toFixed(
      tradeState.store.useSettingsStore().ratePrecision
    );
  }

  return 0;
});

const primaryLPRate = computed(() => {
  if (props.tradePrice?.response) {
    return getPrimaryCcy(props.tradePrice.response).productLpRate?.toFixed(
      tradeState.store.useSettingsStore().ratePrecision
    );
  }

  return 0;
});

const secondaryLPRate = computed(() => {
  if (props.tradePrice?.response) {
    return getSecondaryCcy(props.tradePrice.response).productLpRate?.toFixed(
      tradeState.store.useSettingsStore().ratePrecision
    );
  }

  return 0;
});

const onBehalfOfClient = useOnBehalfOf();

function onIsForwardChange() {
  if (state.clientSettings && !clientRateAndMarginFormDef.form.clientRateMarkup && props.tradeDetails?.timeFrame) {
    if (props.tradeDetails?.timeFrame?.isForward) {
      clientRateAndMarginFormDef.form.clientRateMarkup = state.clientSettings?.fxForwards.profitMargin;
    } else {
      clientRateAndMarginFormDef.form.clientRateMarkup = state.clientSettings?.fxSpots.profitMargin;
    }
  }
}
/**
 * Set defaults on trade details change
 */
watch(() => props.tradeDetails?.timeFrame?.isForward, onIsForwardChange, { immediate: true });

function onClientChange() {
  if (onBehalfOfClient.value) {
    requestManager.manager
      .sameOrCancelAndNew(
        'getClientSettings',
        tradeState.services.spreads.getClientSpreads(onBehalfOfClient.value.id),
        onBehalfOfClient.value.id
      )
      .subscribe((settings) => {
        state.clientSettings = settings;
        onIsForwardChange();
      });
  }
}

watch(() => onBehalfOfClient.value, onClientChange, { immediate: true });

/**
 * Set values on price change
 */
watch(
  () => props.tradePrice,
  () => {
    state.calculating = false;
    if (props.tradePrice?.response?.totalMarkupBasisPoints) {
      setErrorText();
      // FIXME using Math.round to avoid issues with decimal rounding in JS. remove once these are converted to int
      clientRateAndMarginFormDef.form.clientRateMarkup = Math.round(props.tradePrice.response.totalMarkupBasisPoints);
    }
    if (clientRate.value) {
      clientRateAndMarginFormDef.form.clientRate = clientRate.value;
    }
  },
  { immediate: true }
);

function setErrorText(errorText: string = '') {
  state.peErrorText = errorText || '';
  const clientMarkupField = getChildModel(clientRateAndMarginFormDef.form, 'clientRateMarkup')!;
  const clientRateField = getChildModel(clientRateAndMarginFormDef.form, 'clientRate')!;
  if (state.peErrorText) {
    const errors = [
      {
        name: 'peError',
        error: '',
      },
    ];
    setState(clientMarkupField, 'errors', errors);
    setState(clientRateField, 'errors', errors);
  } else {
    setState(clientMarkupField, 'errors', []);
    setState(clientRateField, 'errors', []);
  }
}

function formChange(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    const inputField = formEvent.model.$name;
    const calculatedField = formEvent.model.$name === 'clientRateMarkup' ? 'clientRate' : 'clientRateMarkup';
    state.calculating = true;

    batchSetState(clientRateAndMarginFormDef.form, 'placeholder', {
      [calculatedField]: 'Calculating...',
      [inputField]: '',
    });

    clientRateAndMarginFormDef.form[calculatedField] = '';

    emit('set-markup', { [inputField]: clientRateAndMarginFormDef.form[inputField] });
  }
  touch();
}

function calculatingFields(calculating = true) {
  state.calculating = calculating;
  if (calculating) {
    batchSetState(clientRateAndMarginFormDef.form, 'placeholder', ['clientRateMarkup', 'clientRate'], 'Calculating...');
  } else {
    batchSetState(clientRateAndMarginFormDef.form, 'placeholder', ['clientRateMarkup', 'clientRate'], '');
  }
}

function touch() {
  clientRateAndMarginFormDef.validation?.$touch();
}

watch(
  () => clientRateAndMarginFormDef.validation,
  () => {
    emit('update:validations', clientRateAndMarginFormDef.validation);
  },
  { immediate: true, deep: true }
);

defineExpose({ isFormInvalid, calculatingFields, touch });
</script>

<template>
  <div>
    <div class="client-rate-box px-4 py-3">
      <ValidatedForm
        :fm="clientRateAndMarginFormDef.form"
        :validation.sync="clientRateAndMarginFormDef.validation"
        @form-event="formChange"
      >
        <template #clientRateAndMarginForm.clientRateMarkup:after>
          <span class="client-markup-field-input">bp</span>
        </template>
      </ValidatedForm>
      <TradePriceExchangeRate
        class="text-center text-secondary"
        :tradePriceResponse="tradePrice.response"
        v-if="tradePrice && tradePrice.response"
      />
      <PartnerProfitInfo
        class="partner-profit text-small"
        :calculating="state.calculating"
        :price="tradePrice && tradePrice.response"
      >
        <template #after-content>
          <slot name="after-profit-data" />
        </template>
      </PartnerProfitInfo>
      <VRow class="error-message mb-0" v-if="state.peErrorText">
        <VCol cols="0" class="pr-0"> <IconAlertCircle class="exchange-icon" /> </VCol>
        <VCol>
          <div>{{ state.peErrorText }}</div>
        </VCol>
      </VRow>
    </div>
    <p v-if="tradeCurrencyPair && primaryLPRate && secondaryLPRate" class="text-secondary mt-2 text-center text-small">
      LP rate: {{ tradeCurrencyPair }} {{ primaryLPRate }} (inverse {{ secondaryLPRate }})
    </p>
  </div>
</template>

<style lang="scss" scoped>
.client-rate-box {
  border: 1px solid;
  @include themedBorderColor($color-dark-primary, $color-primary);
}

.error-message {
  @include themedTextColor($color-danger, $color-dark-danger);
}

.partner-profit {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid;
  @include themedBorderColor($color-dark-primary, $color-primary);
}

.client-markup-field-input {
  position: absolute;
  right: 0;
  top: 2.2rem;
  @include upToResolution($tabletResolution) {
    right: -1.1rem;
  }
}

::v-deep {
  .dynamic-form {
    display: inline-flex;
    width: 100%;
  }

  input {
    text-align: center !important;
  }

  .field-group-clear-link {
    position: absolute;
    right: 20px;
  }
}
</style>
