import { render, staticRenderFns } from "./TradeListFilters.vue?vue&type=template&id=1a70b260&scoped=true"
import script from "./TradeListFilters.vue?vue&type=script&lang=ts"
export * from "./TradeListFilters.vue?vue&type=script&lang=ts"
import style0 from "./TradeListFilters.vue?vue&type=style&index=0&id=1a70b260&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a70b260",
  null
  
)

export default component.exports