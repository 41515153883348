<template>
  <div class="card-block">
    <h3>Ultimate Beneficial Owner Information</h3>
    <div>
      <ValidatedForm :fm="uboChoiceForm" :validation.sync="uboChoiceValidation" @form-event="onChoiceChange">
        <template #uboChoiceForm.choice:label>
          <label class="field-group-field-label mb-0">
            <span>Are there any UBOs? *</span>
            <p class="text-small text-muted">
              An Ultimate Beneficial Owner (UBO) is somebody who owns 25% or more of the organisation.
            </p>
          </label>
        </template>
        <template #uboChoiceForm.choice:after>
          <p class="text-small text-muted">
            If there are no identified UBO's for the business, please provide us with a Proof of Authorised Signatory
            Document, which will verify any Authorised users for the account.
          </p>
        </template>
      </ValidatedForm>
      <p class="text-secondary"></p>
      <ExpandTransition appear>
        <div v-if="shouldDisplayUbos">
          <p class="text-small text-muted">
            We need an authorised signatory of the company to sponsor the application. this link will be sent to the
            signatory upon completion.
          </p>
          <div v-for="(uboFormDef, index) in uboUserFormDefs" :key="uboFormDef.ubo.id">
            <hr class="mb-5 mt-4" v-if="index !== 0" />
            <div class="user-holder my-3">
              <div class="d-flex justify-content-between mb-4">
                <h3 class="current-account-heading my-0">UBO</h3>
                <RemoveButton :removeButtonText="'Remove UBO'" @remove="remove(index)" />
              </div>
              <ValidatedForm
                :fm="uboUserFormDefs[index].form"
                :validation.sync="uboUserFormDefs[index].validation"
                @form-event="onFormEvent"
              >
                <template #uboUserForm.email:before>
                  <h3 class="current-account-heading">UBO's Current Address</h3>
                  <RegistrationAddressForm
                    :address="uboFormDef.ubo.currentAddress"
                    @update:address="onUpdateUbo(uboFormDef, { currentAddress: $event })"
                    :validation.sync="uboUserFormDefs[index].currentAddressValidation"
                    :isCountryEditable="true"
                  />
                  <OnboardingPreviousAddressesForms
                    class="mb-4"
                    v-if="isUboAUKResident(uboFormDef.ubo)"
                    :previousAddresses="uboFormDef.ubo.previousAddresses"
                    @update:previousAddresses="onUpdateUbo(uboFormDef, { previousAddresses: $event })"
                    :validation.sync="uboUserFormDefs[index].previousAddressesValidation"
                    :currentAddressResidenceDate="uboFormDef.ubo.currentAddress.residingFrom"
                    @update:currentAddressResidenceDate="
                      onUpdateUbo(uboFormDef, {
                        currentAddress: { ...uboFormDef.ubo.currentAddress, residingFrom: $event },
                      })
                    "
                    :showErrorMessage="showUboAddressHistoryErrors"
                    :isUboLabel="true"
                  />
                </template>
                <template #uboUserForm.phoneNumber:after>
                  <span class="text-muted">
                    <ul class="text-small pl-4 my-1">
                      <li>Must include country code</li>
                    </ul>
                  </span>
                </template>
                <template #uboUserForm.ownershipPercentage:append>
                  <BInputGroupText class="plus-minus">
                    <div class="percent">%</div>
                  </BInputGroupText>
                </template>
                <template #uboUserForm.ownershipPercentage:after>
                  <span class="text-muted">
                    <ul class="text-small pl-0 my-1">
                      You may include up to 2 decimal places
                    </ul>
                  </span>
                </template>
              </ValidatedForm>
              <template v-if="!hideOnboardingDocumentsUploader">
                <FileUploader
                  title="Proof of Address"
                  :maxSize="10485760"
                  description="Clear, colour copy of a utility bill (must be dated within the last 3 months)."
                  :accept="defaultAcceptanceFileTypes"
                  acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
                  :selected="getFile(uboFormDef.ubo, ClientFileCategories.PROOF_OF_ADDRESS)"
                  @update:selected="onFileChange($event, uboFormDef.ubo, ClientFileCategories.PROOF_OF_ADDRESS)"
                  uploadRequest="Don't allow"
                  :auto-upload="false"
                  hide-warning
                />
                <FileUploader
                  title="Proof of ID"
                  :maxSize="10485760"
                  :accept="defaultAcceptanceFileTypes"
                  acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
                  description="Clear, colour copy of driving license or passport (in date, with all information legible)"
                  :selected="getFile(uboFormDef.ubo, ClientFileCategories.PHOTO_ID)"
                  @update:selected="onFileChange($event, uboFormDef.ubo, ClientFileCategories.PHOTO_ID)"
                  uploadRequest="Don't allow"
                  :auto-upload="false"
                  hide-warning
                />
              </template>
            </div>
          </div>
          <div class="text-center">
            <VButton :disabled="maxAllowedUsersReached" class="btn-stroked" @click="addUser"> Add another UBO </VButton>
          </div>
        </div>
        <div v-else-if="!shouldDisplayUbos">
          <FileUploader
            :title="resolutionLetterTitle"
            :description="resolutionLetterDescription"
            tooltip
            :maxSize="10485760"
            :accept="defaultAcceptanceFileTypes"
            acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
            :uploadRequest="uploadResolutionLetter"
            :downloadRequest="downloadFile"
            :deleteRequest="deleteFile"
            :uploaded="resolutionLetter"
            hide-warning
          >
            <template #description-after>
              If you cannot provide this at this stage, please login to your platform to upload this at a later stage.
              It will be required before account approval.
            </template>
          </FileUploader>
        </div>
      </ExpandTransition>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { makeFormModel, toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { radioField, textField, emailField, phoneField, financialAmountField } from 'ah-common-lib/src/form/models';
import TermsAndConditionsLink from '@/app/components/common/TermsAndConditionsLink.vue';
import {
  checkAddressHistoryValidity,
  ClientFileCategories,
  ClientFileCategoriesToHuman,
  ClientFileCategoryDescriptions,
  getAddressHistoryAge,
  MIN_ADDRESS_HISTORY_AGE,
  UnsubmittedUbo,
  UploadedFile,
  VersionedObject,
} from 'ah-api-gateways';
import { generateTempUUID } from 'ah-common-lib/src/helpers/uuid';
import { FormModel, FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { cloneDeep, isEqual } from 'lodash';
import { requiredIfStateValue, optional, checkParam, validName } from 'ah-common-lib/src/form/validators';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import { personTitleField } from 'ah-common-lib/src/form/formModels';
import { defaultAcceptanceFileTypes } from 'ah-common-lib/src/helpers/file';
import { map, tap } from 'rxjs/operators';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { waitForEntityChange } from 'ah-requests';
import { registrationDateField } from '@/app/helpers/registration/forms';
import { useFEFeatureFlag } from 'ah-common-stores';
import RegistrationAddressForm from '../common/RegistrationAddressForm.vue';
// @ts-ignore
import OnboardingPreviousAddressesForms from '../common/OnboardingPreviousAddressesForms.vue';
import RemoveButton from '../common/RemoveButton.vue';
import { required } from '@vuelidate/validators';

const uboUserFM = (useDOB = false) =>
  makeFormModel({
    name: 'uboUserForm',
    fieldType: 'form',
    fields: [
      personTitleField({ fieldWrapperClass: 'col col-2', showRequiredMarkers: true }),
      textField(
        'firstName',
        'First Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Last Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      financialAmountField(
        'ownershipPercentage',
        'Percentage of Ownership',
        {
          fieldWrapperClass: 'col col-5',
          label: 'You may include up to 2 decimal places.',
          errorMessages: {
            ownershipLimits: 'Must be a value between 25% and 100%',
          },
        },
        {
          required,
          ownershipLimits: optional((value) => value >= 25 && value <= 100),
        }
      ),
      emailField('email', 'Email Address', { showRequiredMarkers: true }),
      phoneField(
        'phoneNumber',
        'Mobile Number',
        { fieldWrapperClass: 'col col-6' },
        {
          phone: optional(checkParam('phoneNumber', 'valid')),
        }
      ),
      ...(useDOB ? [registrationDateField({ fieldWrapperClass: 'col col-6', showRequiredMarkers: true })] : []),
    ],
  });

const uboChoiceFM = () =>
  makeFormModel({
    name: 'uboChoiceForm',
    fieldType: 'form',
    fields: [
      radioField(
        'choice',
        'Are there any UBOs?',
        [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
        {
          defaultValue: 'no',
          inline: true,
          fieldWrapperClass: 'mb-0 col col-12',
          showRequiredMarkers: true,
        }
      ),
    ],
  });

const MAX_ALLOWED_UBOS = 4;

interface UboFormDef {
  ubo: UnsubmittedUbo;
  form: FormModel;
  validation: FormValidation | null;
  currentAddressValidation: FormValidation | null;
  previousAddressesValidation: FormValidation | null;
}

/**
 * Ultimate beneficiary owner(s) form
 *
 * The current component will display a form to add (if any) `maxUBO` number
 * of UBO users.
 *
 * Emits:
 * - update:ubos (payload: UnsubmittedUbo[])
 * - update:files (payload: UploadedFile[])
 */
@Component({
  components: {
    TermsAndConditionsLink,
    FileUploader,
    RegistrationAddressForm,
    OnboardingPreviousAddressesForms,
    RemoveButton,
  },
  setup() {
    const dobInOnboardingFeatureActive = useFEFeatureFlag('dobInOnboardingFeatureActive');
    const hideOnboardingDocumentsUploader = useFEFeatureFlag('hideOnboardingDocumentsUploader');

    return {
      dobInOnboardingFeatureActive,
      hideOnboardingDocumentsUploader,
    };
  },
})
export default class UBOForm extends Vue {
  @Prop({ default: () => [] }) ubos!: UnsubmittedUbo[];

  /**
   * Files presented to client
   */
  @Prop({ default: () => [] }) files!: UploadedFile[];

  @Prop({ default: false }) showUboAddressHistoryErrors!: boolean;

  private dobInOnboardingFeatureActive!: boolean;

  private hideOnboardingDocumentsUploader!: boolean;

  private ClientFileCategories = ClientFileCategories;

  private uboChoiceForm = uboChoiceFM();

  private uboChoiceValidation: FormValidation | null = null;

  private uboUserFormDefs: UboFormDef[] = [];

  private defaultAcceptanceFileTypes = defaultAcceptanceFileTypes;

  private innerFiles: UploadedFile[] = [];

  private resolutionLetterTitle = ClientFileCategoriesToHuman[ClientFileCategories.RESOLUTION_LETTER];

  private resolutionLetterDescription = ClientFileCategoryDescriptions[ClientFileCategories.RESOLUTION_LETTER];

  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  isUboAUKResident(ubo?: UnsubmittedUbo) {
    return ubo?.currentAddress && ubo.currentAddress.countryCode === 'GB';
  }

  uboAddressValidity(ubo: UnsubmittedUbo) {
    return checkAddressHistoryValidity(ubo);
  }

  get resolutionLetter() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.RESOLUTION_LETTER) || null;
  }

  get shouldDisplayUbos() {
    return this.uboChoiceForm.choice === 'yes';
  }

  get validUploadedFiles() {
    return true;

    // FIXME: documents are no longer required in UBOs (temporary fix)

    // if (!this.shouldDisplayUbos) {
    //   return true;
    // } else {
    //   const anyIncompleteUboFiles = this.uboUsers.find((user) =>
    //     [ClientFileCategories.PROOF_OF_ADDRESS, ClientFileCategories.PHOTO_ID].find(
    //       (cat) =>
    //         !user.documents.find((d) => d.category === cat) &&
    //         !user.readyToUpload?.find((d) => d.category === cat && d.file)
    //     )
    //   );

    //   return !anyIncompleteUboFiles;
    // }
  }

  get validation() {
    let areAnyUbosInvalid = false;
    let areAnyUbosDirty = false;

    if (this.uboChoiceForm.choice === 'yes') {
      areAnyUbosInvalid = this.uboUserFormDefs?.some(
        (formDef) =>
          formDef.currentAddressValidation?.$invalid ||
          (this.isUboAUKResident(formDef.ubo) && formDef.previousAddressesValidation?.$invalid) ||
          formDef.validation?.$invalid
      );
      areAnyUbosDirty = this.uboUserFormDefs?.some(
        (formDef) =>
          formDef.currentAddressValidation?.$dirty ||
          (this.isUboAUKResident(formDef.ubo) && formDef.previousAddressesValidation?.$dirty) ||
          formDef.validation?.$dirty
      );
    }

    return {
      $model: null,
      $invalid: this.uboChoiceValidation?.$invalid || !this.validUploadedFiles || areAnyUbosInvalid,
      $dirty: !!this.uboChoiceValidation?.$dirty || areAnyUbosDirty,
    };
  }

  makeEmptyUbo(): UnsubmittedUbo {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      birthDate: '',
      ownershipPercentage: 25,
      email: '',
      id: generateTempUUID(),
      documents: [],
      currentAddress: {
        addressLine: '',
        city: '',
        postalCode: '',
        stateOrProvince: '',
        countryCode: '',
        residingFrom: '',
      },
      previousAddresses: [],
      readyToUpload: [
        { category: ClientFileCategories.PROOF_OF_ADDRESS, file: null },
        { category: ClientFileCategories.PHOTO_ID, file: null },
      ],
    };
  }

  get maxAllowedUsersReached() {
    return this.uboUserFormDefs.length >= MAX_ALLOWED_UBOS;
  }

  get hasSufficientAddressHistory() {
    const addressHistoryAge = getAddressHistoryAge({
      currentAddress:
        this.uboUserFormDefs.find((uboFormDef) => uboFormDef.ubo.currentAddress)?.ubo.currentAddress || {},
      previousAddresses: this.uboUserFormDefs.flatMap((uboFormDef) => uboFormDef.ubo.previousAddresses),
    });
    return addressHistoryAge >= MIN_ADDRESS_HISTORY_AGE;
  }

  private onUpdateUbo(uboFormDef: UboFormDef, update: Partial<UnsubmittedUbo>) {
    Object.assign(uboFormDef.ubo, update);
    this.emitUbosUpdate();
  }

  private emitUbosUpdate() {
    this.$emit(
      'update:ubos',
      this.uboUserFormDefs.map((item) => item.ubo)
    );
  }

  private uploadResolutionLetter(file: File) {
    return this.$services.client
      .uploadDocument(this.authStore.loggedInIdentity!.client!.id, ClientFileCategories.RESOLUTION_LETTER, file)
      .pipe(
        tap((update) => {
          if (update.finished) {
            this.waitForDocument(update.file).subscribe((document) => {
              if (document) {
                this.individualSettingsStore.setClientDocument({ document });
                const currFileIndex = this.innerFiles.findIndex((i) => i.category === document.category);

                currFileIndex > -1
                  ? this.innerFiles.splice(currFileIndex, 1, document)
                  : this.innerFiles.push(document);
              }
            });
          }
        })
      );
  }

  private waitForDocument(file: VersionedObject) {
    return waitForEntityChange(
      () => this.$services.client.getDocuments(this.authStore.loggedInIdentity!.client!.id),
      (docs) => {
        return !!docs.find((d) => d.id === file.id);
      }
    ).pipe(map((docs) => docs.find((d) => d.id === file.id)!));
  }

  private deleteFile(file: UploadedFile) {
    return this.$services.client.deleteDocument(this.authStore.loggedInIdentity!.client!.id, file.id).pipe(
      tap(() => {
        const currFileIndex = this.innerFiles.findIndex((i) => i.category === file.category);
        if (currFileIndex > -1) {
          this.innerFiles.splice(currFileIndex, 1);
        }

        this.individualSettingsStore.unsetClientDocument({ category: file.category as ClientFileCategories });
      })
    );
  }

  private downloadFile(file: UploadedFile) {
    return this.$services.client.downloadSyncDocument(this.authStore.loggedInIdentity!.client!.id, file);
  }

  private getFile(user: UnsubmittedUbo, category: ClientFileCategories) {
    return (
      user.readyToUpload?.find((file) => file.category === category)?.file ||
      user.documents?.find((file) => file.category === category)
    );
  }

  private onFileChange(file: File, user: UnsubmittedUbo, category: ClientFileCategories) {
    if (file === null) {
      this.onFileremoved(file, user, category);
    }
    this.$set(user, 'readyToUpload', user.readyToUpload || []);
    let userFile = user.readyToUpload!.find((file) => file.category === category)!;
    if (!userFile) {
      userFile = { category, file: file };
      user.readyToUpload!.push(userFile);
    } else {
      userFile.file = file;
    }
    this.emitUbosUpdate();
  }

  private onFileremoved(file: File, user: UnsubmittedUbo, category: ClientFileCategories) {
    user.readyToUpload = user.readyToUpload || [];

    let uploaded = user.documents!.find((file) => file.category === category)!;
    let userFile = user.readyToUpload!.find((file) => file.category === category)!;
    if (uploaded) {
      user.documents.splice(user.documents.indexOf(uploaded), 1);
    }

    if (!userFile) {
      userFile = { category, file: null };
      user.readyToUpload!.push(userFile);
    } else {
      userFile.file = null;
    }

    this.emitUbosUpdate();
  }

  private onFormEvent(event: FormEvent<any>) {
    if (event.event === 'form-field-set-value') {
      this.uboUserFormDefs.forEach((uboFormDef) => {
        uboFormDef.ubo = {
          ...uboFormDef.ubo,
          ...toDataModel(uboFormDef.form),
          documents: uboFormDef.ubo.documents,
          readyToUpload: uboFormDef.ubo.readyToUpload,
        };
      });
      this.emitUbosUpdate();
    }
  }

  private onChoiceChange(event: FormEvent) {
    if (event.event === 'form-field-set-value' && this.shouldDisplayUbos && (!this.ubos || this.ubos.length === 0)) {
      this.addUser();
    } else if (event.event === 'form-field-set-value' && !this.shouldDisplayUbos) {
      this.uboUserFormDefs = [];
      this.emitUbosUpdate();
    }
  }

  private remove(index: number) {
    if (this.uboUserFormDefs.length > 1) {
      this.uboUserFormDefs.splice(index, 1);
      this.emitUbosUpdate();
    } else {
      this.uboUserFormDefs = [];
      this.$emit('update:ubos', null);
      this.uboChoiceForm.choice = 'no';
    }
  }

  private addUser(ubo?: UnsubmittedUbo) {
    this.uboUserFormDefs.push({
      form: uboUserFM(this.dobInOnboardingFeatureActive),
      currentAddressValidation: null,
      previousAddressesValidation: null,
      validation: null,
      ubo: {
        id: generateTempUUID(),
        ...(ubo ? cloneDeep(ubo) : this.makeEmptyUbo()),
      },
    });
    if (!ubo) {
      this.emitUbosUpdate();
    } else {
      updateModel(this.uboUserFormDefs[this.uboUserFormDefs.length - 1].form, ubo);
    }
  }

  @Watch('files', { immediate: true })
  onUboFilesChange() {
    if (this.files !== null) {
      this.innerFiles = this.files;
    }
  }

  @Watch('ubos', { immediate: true })
  @Watch('dobInOnboardingFeatureActive')
  onUbosChange() {
    this.uboUserFormDefs = [];
    this.ubos?.forEach((ubo) => this.addUser(ubo));
    this.uboChoiceForm.choice = this.uboUserFormDefs.length > 0 ? 'yes' : 'no';
  }

  @Watch('innerFiles')
  onFilesChange() {
    if (!isEqual(this.innerFiles, this.files)) {
      this.$emit('update:files', this.innerFiles);
    }
  }

  @Watch('validation', { immediate: true })
  onValidationChange() {
    this.$emit('update:validation', this.validation);
  }
}
</script>

<style lang="scss" scoped>
.user-holder {
  position: relative;
}

.close-button {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 1.3rem;
  top: -0.5rem;
  ::v-deep svg {
    path {
      @include themedPropColor('fill', $color-primary);
    }
  }
}
.current-account-heading {
  font-size: $base-font-size;
  font-weight: $font-weight-semibold;
  @include themedTextColor($color-primary);
}
</style>
