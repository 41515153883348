<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/app/store/authStore';
import { VButton } from 'ah-common-lib/src/common/components';
import ShareIcon from 'ah-common-lib/src/icons/components/ShareIcon.vue';
import CopyIcon from 'ah-common-lib/src/icons/components/CopyIcon.vue';
import CheckMiniIcon from 'ah-common-lib/src/icons/components/CheckMiniIcon.vue';
import { useClipboard } from '@vueuse/core';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const BASE_URL = `${window.location.protocol}//${window.location.host}`;
const REFERRAL_PATH = '/referral';

const services = getServices();
const authStore = useAuthStore();
const requestManager = useRequestManager().manager;
const showReferralContainer = ref(false);
const referralContainerRef = ref<HTMLElement>();
const showCopiedPopover = ref(false);
const copyButton = ref<InstanceType<typeof VButton>>();
const referralLink = ref('');

const clientId = computed(() => authStore.loggedInIdentity?.client?.id);

const toggleReferralContainer = (event: Event) => {
  event.stopPropagation();
  showReferralContainer.value = !showReferralContainer.value;
  if (showReferralContainer.value && !referralLink.value) {
    getReferralLink();
  }
};

const closeReferralContainer = (event: MouseEvent) => {
  if (
    showReferralContainer.value &&
    referralContainerRef.value &&
    !referralContainerRef.value.contains(event.target as Node)
  ) {
    showReferralContainer.value = false;
  }
};

const { copy } = useClipboard();

const copyReferralLink = () => {
  copy(referralLink.value);
  showCopiedPopover.value = true;
  setTimeout(() => {
    showCopiedPopover.value = false;
  }, 2000);
};

const selectInputText = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.select();
};

function getReferralLink() {
  if (clientId.value) {
    requestManager
      .sameOrNew('loadReferralLink', services.registration.getClientReferralLink(clientId.value))
      .subscribe({
        next: (response) => {
          const { source, clientId: responseClientId, individualId } = response;
          const params = new URLSearchParams({
            source,
            clientId: responseClientId,
            individualId,
          });
          referralLink.value = `${BASE_URL}${REFERRAL_PATH}?${params.toString()}`;
        },
      });
  }
}

onMounted(() => {
  document.addEventListener('click', closeReferralContainer);
  getReferralLink();
});

onUnmounted(() => {
  document.removeEventListener('click', closeReferralContainer);
});
</script>

<template>
  <div class="app-referral">
    <VButton
      blurOnClick
      v-if="authStore.isClientUser"
      class="refer-button btn-primary"
      @click="toggleReferralContainer"
    >
      Refer <ShareIcon />
    </VButton>

    <div v-if="showReferralContainer" class="referral-container" ref="referralContainerRef">
      <div class="referral-container-upper">
        <h4>Refer to ALT21</h4>
        <p>Your friends can now do business smarter with ALT21.</p>
      </div>

      <div class="referral-container-bottom">
        <p>Share this link with your friends:</p>
        <div class="referral-input-container">
          <input type="text" :value="referralLink" readonly class="referral-input" @click="selectInputText" />
          <VButton class="copy-btn btn-primary" @click="copyReferralLink" ref="copyButton">
            <CopyIcon />
          </VButton>
          <div v-if="showCopiedPopover" class="custom-popover">
            <CheckMiniIcon class="check-icon" />
            <span>Copied!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-referral {
  position: relative;

  .refer-button {
    padding: 4px 8px;
    border-radius: 6px;
    margin: 0.7em;
    font-weight: $font-weight-medium;

    svg {
      color: $white;
    }
  }

  .referral-container {
    position: absolute;
    top: 90%;
    left: 0;
    z-index: 99;
    @include themedBackgroundColor($color-card-bg, $color-dark-box-bg);
    border-radius: 8px;
    height: auto;
    width: 550px;
    margin-left: 0.7em;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px;

    .referral-container-upper {
      padding: 1em 1.5em;
      @include themedBackgroundColor($color-light-cyan, $color-dark-box-bg);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 77px;
      border-bottom: 1px solid #e5e7eb;

      h4 {
        font-weight: $font-weight-semibold;
        font-size: $base-font-size;
        font-family: $font-family-inter;
        @include themedTextColor($color-text-black, $color-dark-text);
      }

      p {
        font-size: 0.875em;
        font-weight: $font-weight-medium;
        font-family: $font-family-inter;
        @include themedTextColor($color-text, $color-dark-text-secondary);
      }
    }

    .referral-container-bottom {
      padding: 1.25em 1.5em;

      p {
        font-size: 0.875em;
        font-weight: $font-weight-regular;
        font-family: $font-family-inter;
        @include themedTextColor($color-text-black, $color-dark-text);
        margin-left: 3px;
        margin-bottom: 10px;
      }

      .referral-input-container {
        position: relative;
        display: block;

        .referral-input {
          padding: 0.75em 3.5em 0.75em 0.75em;
          width: 100%;
          height: auto;
          border-radius: 6px;
          border: 1px solid #d1d5db;
          color: #434d59;
          font-weight: $font-weight-regular;
          font-size: 0.875em;
          transition: border-color 0.2s ease-in-out;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &:focus {
            outline: none;
            border-color: $widget-cyan;
            box-shadow: 0 0 0 2px rgba(17, 100, 102, 0.2);
          }
        }

        .copy-btn {
          position: absolute;
          right: 0.5em;
          top: 50%;
          transform: translateY(-50%);
          padding: 6px 12px;
          border-radius: 6px;

          svg {
            width: 1.25em;
            height: 1.25em;
          }
        }

        .custom-popover {
          color: $white;
          position: absolute;
          top: -40px;
          right: 0;
          @include themedBackgroundColor($color-grayish-blue, $color-dark-primary);
          padding: 5px 10px 5px 5px;
          border-radius: 0.37em;
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          font-weight: $font-weight-medium;

          &::after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 0.3em solid transparent;
            border-right: 0.3em solid transparent;
            border-top: 5px solid $grayish-blue;
          }
          .check-icon {
            width: 1.25em;
            height: 1.25em;
          }
        }
      }
    }
  }
}
</style>
