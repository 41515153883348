import { render, staticRenderFns } from "./WithdrawFromWallet.vue?vue&type=template&id=65de6059&scoped=true"
import script from "./WithdrawFromWallet.vue?vue&type=script&setup=true&lang=ts"
export * from "./WithdrawFromWallet.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./WithdrawFromWallet.vue?vue&type=style&index=0&id=65de6059&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65de6059",
  null
  
)

export default component.exports