<template>
  <div x-test-name="representative-form">
    <h2>Contact Details</h2>
    <ValidatedForm :fm="accountAccessFM" :validation.sync="accountAccessValidation" @form-event="onFormEvent">
      <template #accountAccess.phoneNumber:after>
        <span class="text-muted">
          <ul class="text-small pl-4 my-1">
            <li>Must include country code</li>
          </ul>
          <span>You will need to confirm this number before completing registration.</span>
        </span>
      </template>
      <template #accountAccess.email:after>
        <span class="text-muted">
          <span>You will need to confirm this email before completing registration.</span>
        </span>
      </template>
      <template #accountAccess.password:after>
        <div v-if="accountAccessValidation">
          <SecurePasswordChecker :validation="accountAccessValidation.password" />
        </div>
      </template>
    </ValidatedForm>
    <div class="buttons-holder">
      <VButton @click="submit" :loading="requestManager.anyPending" :disabled="validation && validation.$invalid">
        Continue
      </VButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator';
import { toDataModel, setState, getChildModel, updateModel } from 'ah-common-lib/src/form/helpers';
import {
  AboutUsReference,
  aboutUsReferenceOptions,
  BaseCompanyUserRegistrationRequest,
  ClientType,
  CompanyRegistrationModel,
  UserCreationRequest,
} from 'ah-api-gateways';
import { SecurePasswordChecker } from 'ah-common-lib/src/form/components';
import { FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { accountAccessFM } from '@/app/helpers/registration/forms';
import { submitUser } from '@/app/helpers/registration/requests';

/**
 * Representative account access form
 */
@Component({
  components: { SecurePasswordChecker },
})
export default class RepresentativeForm extends WithRequestManager {
  @Prop({ required: true }) model!: Partial<CompanyRegistrationModel>;

  private accountAccessFM = accountAccessFM({ isCompanyApplicant: true });

  private accountAccessValidation: FormValidation | null = null;

  get calculatedModel(): UserCreationRequest {
    let { jobTitle, reference, referenceSelection, ...applicant } = toDataModel(this.accountAccessFM);

    if (this.accountAccessFM.referenceSelection !== AboutUsReference.OTHER) {
      reference = this.accountAccessFM.referenceSelection;
    }

    return {
      applicant,
      registrationData: {
        jobTitle,
        questionnaireAnswer: reference?.length > 0 ? reference : undefined,
        incorporationCountry: this.model.address?.countryCode,
        referralId: this.model.referralId,
      } as BaseCompanyUserRegistrationRequest,
    };
  }

  get validation() {
    return {
      $model: this.calculatedModel,
      $invalid: !!this.accountAccessValidation?.$invalid,
      $dirty: !!this.accountAccessValidation?.$dirty,
    };
  }

  onFormEvent(formEvent: FormEvent) {
    if (formEvent.event === 'form-field-set-value') {
      setState(this.accountAccessFM, 'errors', [], true);
      const { referenceDescription, referenceSelection, ...applicant } = toDataModel(this.accountAccessFM);
      if (formEvent.model.$name === 'referenceSelection') {
        this.checkReferenceDescriptionDisplay();
        this.accountAccessFM.referenceDescription = '';
        if (this.accountAccessValidation?.referenceDescription) {
          this.accountAccessValidation.referenceDescription.$reset();
        }
      }
      this.$emit('update:model', {
        ...this.model,
        applicant: { ...this.model.applicant, ...applicant },
        reference: referenceSelection === AboutUsReference.OTHER ? referenceDescription : referenceSelection,
      });
    }
  }

  submit() {
    this.requestManager
      .sameOrCancelAndNew(
        'registerUser',
        submitUser(this.model.clientType ?? ClientType.COMPANY, this.calculatedModel, this.accountAccessFM)
      )
      .subscribe(() => this.$emit('proceed'));
  }

  checkReferenceDescriptionDisplay() {
    const field = getChildModel(this.accountAccessFM, 'referenceDescription')!;
    setState(field, 'hidden', this.accountAccessFM.referenceSelection !== AboutUsReference.OTHER);
    setState(field, 'required', this.accountAccessFM.referenceSelection === AboutUsReference.OTHER);
  }

  @Watch('model', { immediate: true })
  onModelChange() {
    updateModel(this.accountAccessFM, { ...this.model?.applicant });

    if (typeof this.model?.reference === 'string') {
      if (
        aboutUsReferenceOptions
          .map((obj) => obj.value)
          .filter((v) => v !== AboutUsReference.OTHER)
          .includes(this.model.reference)
      ) {
        this.accountAccessFM.referenceSelection = this.model.reference;
      } else {
        this.accountAccessFM.referenceSelection = AboutUsReference.OTHER;
        this.accountAccessFM.referenceDescription = this.model.reference;
      }
      this.checkReferenceDescriptionDisplay();
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}
</style>
