import { HttpService, HttpBasedService, HttpRequestOptions, HttpOptions } from 'ah-requests';
import { CutoffTimeModel, DrawdownQuotePriceResponse, FxOperation, HedgingInstruments } from '../models';
import { TradeableDays, NonTradeableDays, AmountType } from '../models';
import {
  SpotQuotePriceRequest,
  QuotePriceResponse,
  ForwardQuotePriceRequest,
  WindowForwardQuotePriceRequest,
  AmendQuotePriceRequest,
  CreateVanillaOptionsPriceRequest,
  VanillaPriceResponse,
} from '../models/quote/pricing';
import { map } from 'rxjs/operators';

export class PricingEngineService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string, private adminBaseUrl?: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'pricingEngineService' } },
      },
    });
  }

  getTradeableDays(hedgingProduct: HedgingInstruments, currencyPair: string, clientId?: string) {
    return this.get<TradeableDays>(`${this.baseUrl}trading-thresholds/valid-days`, {
      axiosConfig: {
        params: { hedgingProduct, currencyPair, clientId },
      },
    });
  }

  getNonTradeableDays(hedgingProduct: HedgingInstruments, currencyPair: string, clientId?: string) {
    return this.get<NonTradeableDays>(`${this.baseUrl}trading-thresholds/invalid-days`, {
      axiosConfig: {
        params: { hedgingProduct, currencyPair, clientId },
      },
      options: {
        cache: {
          type: 'use',
          cacheKey: 'currencyCutoffInfo',
          itemKey: `${hedgingProduct}-${currencyPair}`,
        },
      },
    });
  }

  createSpotQuote(spotQuoteRequest: SpotQuotePriceRequest, options?: Partial<HttpRequestOptions<QuotePriceResponse>>) {
    return this.post<QuotePriceResponse>(`${this.baseUrl}prices/spot`, spotQuoteRequest, options);
  }

  createForwardQuote(
    forwardQuoteRequest: ForwardQuotePriceRequest,
    options?: Partial<HttpRequestOptions<QuotePriceResponse>>
  ) {
    return this.post<QuotePriceResponse>(`${this.baseUrl}prices/forward`, forwardQuoteRequest, options);
  }

  createFlexibleForwardQuote(
    forwardQuoteRequest: ForwardQuotePriceRequest,
    options?: Partial<HttpRequestOptions<QuotePriceResponse>>
  ) {
    return this.post<QuotePriceResponse>(`${this.baseUrl}prices/flexible-forward`, forwardQuoteRequest, options);
  }

  createWindowForwardQuote(
    windowForwardQuoteRequest: WindowForwardQuotePriceRequest,
    options?: Partial<HttpRequestOptions<QuotePriceResponse>>
  ) {
    return this.post<QuotePriceResponse>(`${this.baseUrl}prices/window-forward`, windowForwardQuoteRequest, options);
  }

  updateQuote(
    amendQuotePriceRequest: AmendQuotePriceRequest,
    options?: Partial<HttpRequestOptions<QuotePriceResponse>>
  ) {
    if (!this.adminBaseUrl) throw 'Forbidden action';
    return this.post<QuotePriceResponse>(`${this.adminBaseUrl}prices/amend`, amendQuotePriceRequest, options);
  }

  createDrawdownQuote(
    tradeId: string,
    drawdownAmount: number,
    amountType: AmountType,
    oboClientId?: string,
    options?: Partial<HttpRequestOptions<DrawdownQuotePriceResponse>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<DrawdownQuotePriceResponse>(
      `${this.baseUrl}price/drawdowns/trade/${tradeId}`,
      {
        drawdownAmount: drawdownAmount,
        amountType: amountType,
      },
      {
        ...options,
        axiosConfig: { headers },
      }
    );
  }

  public getCutoffTime(
    currencyPair: string,
    clientId: string,
    fxOperation: FxOperation,
    tradeId?: string,
    oboClientId?: string,
    options?: HttpOptions<CutoffTimeModel>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.get<CutoffTimeModel>(`${this.baseUrl}trading-thresholds/trading-cutoff`, {
      axiosConfig: {
        headers,
        params: {
          currencyPair,
          clientId,
          fxOperation,
          tradeId,
        },
      },
      options,
    });
  }

  public createVanillaOptionsQuote(
    vanillaOptions: Partial<CreateVanillaOptionsPriceRequest>,
    oboClientId?: string,
    options?: Partial<HttpOptions<VanillaPriceResponse>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<VanillaPriceResponse>(`${this.baseUrl}price/options/vanilla-options`, vanillaOptions, {
      options,
      axiosConfig: { headers },
    });
  }

  public getVanillaOptionsSpotRate(
    vanillaOptions: Partial<CreateVanillaOptionsPriceRequest>,
    oboClientId?: string,
    options?: Partial<HttpOptions<Partial<VanillaPriceResponse>>>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) headers['x-ah-on-behalf-of'] = oboClientId;

    return this.post<Partial<VanillaPriceResponse>>(
      `${this.baseUrl}price/options/vanilla-options/base-spot-rate`,
      vanillaOptions,
      {
        options,
        axiosConfig: { headers },
      }
    );
  }

  public getVanillaOptionsQuote(id: string, options?: Partial<HttpOptions<VanillaPriceResponse>>) {
    return this.get<VanillaPriceResponse>(`${this.baseUrl}price/options/vanilla-options/price/${id}`, {
      options: {
        ...options,
      },
    });
  }

  public getSyncOptionsPdfBlob(id: string) {
    return this.getOptionsTermsheet(id, 'application/pdf').pipe(map((r) => r.data));
  }

  public getOptionsTermsheet(id: string, filetype = 'application/octet-stream') {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        headers: {
          Accept: filetype,
        },
        url: `${this.baseUrl}prices/${id}/export-termsheet`,
      },
    });
  }
}
